import { MenuGroupDescriptor, Page } from "@shopify/polaris";
import { } from "common";
import { DataService } from "data-service";
import { useAngular } from "react-utils";
import { FormsQuestionService, useBranchSelector } from "../utils";
import { AutopayTable } from "./useAutopayTable";

export function AutopayBranchPage() {
  const { get } = useAngular();
  const data = get(DataService);
  const fq = get(FormsQuestionService);

  const actionGroups: MenuGroupDescriptor[] = [];
  const { curBranch, branchSelectorActionGroup } = useBranchSelector();

  if (data.status.branchType === "CENTRAL" && branchSelectorActionGroup)
    actionGroups.push(branchSelectorActionGroup);

  // const tableMarkup = useAutopayTable(curBranch, 2);

  return <Page title="Past Due (with Autopay)" actionGroups={actionGroups} fullWidth>
    <p>Lines in this list are past due and autopay won't run.</p>
    <ul>
      <li><p>Customer Ready: Customer has verified email, signed storage agreement, and valid payment info.</p></li>
      <li><p>Customer Autopay: Whether the customer has autopay enabled.</p></li>
      <li><p>Should Autopay: Whether the line is eligible for autopay based on criteria of the line itself. Only unit rental (and rent-to-own) lines get autopay, not other fees or products. Sales tax is included. The first month rental also does not autopay.</p></li>
      <li><p>Old Lines Paid: If this rental has previous month rentals that are not paid, it won't try this month. The account needs to be up to date.</p></li>
      <li><p>Autopay Attempts: The number of times autopay has been attempted for this line. Usually we only try once. If the card declines, you need to do it manually. </p></li>
      <li><p>Date: Autopay will only be attempted on lines dated within the last 5 days. Lines before that needs to be taken care of manually.</p></li>
    </ul>
    <AutopayTable curBranch={curBranch} curTab={2} />
  </Page>;

}
