/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useLayoutEffect } from "react";

/**
 * Custom hook that combines `useLayoutEffect` and a watcher for dependencies.
 * The effect only runs when the `watch` dependencies change and captures the closure when the `deps` change.
 *
 */
export function useLayoutEffectWatcher(
  /** The effect to be executed. */
  effect: React.EffectCallback,
  /** An optional array of dependencies for the effect. */
  deps: React.DependencyList | undefined,
  /** An optional array of dependencies to watch for changes. */
  watch: React.DependencyList | undefined,
  /** If true, the effect will be run on unmount instead of mount and returning a function will have no effect. */
  runOnUnmount?: boolean
) {
  const callback = React.useRef(() => { });
  useLayoutEffect(() => { callback.current = effect; }, deps);
  useLayoutEffect(runOnUnmount ? () => () => callback.current() : () => callback.current(), watch);
}