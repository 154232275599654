import { MenuGroupDescriptor, Spinner, EmptyState } from '@shopify/polaris';
import React from "react";
import pluralize from 'pluralize';
import { useI18n } from '@shopify/polaris/utilities/i18n';
import { ActionButton } from './ActionButton';

export function useEmptyBranchMarkup(b: MenuGroupDescriptor | undefined) {
  const emptyBranchMarkup = () => b ? (
    <EmptyState
      heading="No branch selected"
      image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
    ><ActionButton label={b.title} actions={b.actions}></ActionButton></EmptyState>
  ) : null;
  return emptyBranchMarkup;
}

export function useLoadingMarkup(plural: string) {
  const i18n = useI18n();
  const loadingMarkup = () => (
    <div className="w-full p-4">
      <div className="Polaris-IndexTable__LoadingPanelRow">
        <Spinner size="small" />
        <span className="Polaris-IndexTable__LoadingPanelText">
          {i18n.translate(
            'Polaris.IndexTable.resourceLoadingAccessibilityLabel',
            { resourceNamePlural: plural, }
          )}
        </span>
      </div>
    </div>
  );
  return loadingMarkup;
}
