import { useState } from 'react';
import { Navigation } from '@shopify/polaris';
import { TABLE_NAMES, } from "common";
import { DataService } from "data-service";
import { SubNavigationItem } from '@shopify/polaris/components/Navigation/types';
import { LedgerTables } from '../utils';
import { useAngular, } from "react-utils";
import { useBranchSelector } from "../utils";
import { useLocation, useNavigate } from 'react-router';

export default function AppLeftBar({ onDismiss }: { onDismiss: () => void }) {
  const { get } = useAngular();
  const data = get(DataService);

  const branches = useBranchSelector();
  const navigate = useNavigate();

  const cur = () => branches.curBranchesMap.get(branches.curBranch);
  const url = (path: string) => `/${path}/${branches.curBranch}`;
  const homePage = () => cur()?.DisplayName ?? "Dashboard";

  const level1 = (label: string, url?: () => string, children?: SubNavigationItem[]) => {
    return <Level {...{ label, url, keepOpen: true, children }} />;
  }
  const level2 = (label: string, url: () => string): SubNavigationItem => {
    return { label, url: url(), onClick: () => { if (url()) navigate(url()) } };
  }
  const levelList2 = (e: TABLE_NAMES, title = `${e} List`) => {
    return level2(title, () => `/${e}/list`);
  }

  const Level = ({ label, icon = "", url = () => "", children, keepOpen }: {
    label: string;
    icon?: string;
    url?: () => string;
    children?: SubNavigationItem[];
    keepOpen?: boolean;
  }) => {
    const location = useLocation();
    const initial = !url() && !!children?.find(e => e.url === location.pathname)
    const [curExpanded, setExpanded] = useState(initial);
    return <Navigation.Item
      url={url()}
      icon={icon}
      label={label}
      subNavigationItems={children}
      expanded={keepOpen || curExpanded}
      selected={false}
      onClick={() => { if (url()) navigate(url()); else setExpanded(e => !e); }}
    />;
  }

  const LevelList = ({ table, title = `${table} List`, url = () => `/${table}/list` }: { table: TABLE_NAMES; title?: string; url?: () => string; }) => {
    return <Level {...{ label: title, icon: '', url }} />
  }

  if (!data.status) return <Navigation location="/" />;

  const isAdmin = data.status.isAdmin;
  const isArlen = data.status.isArlen;

  return (
    <Navigation location="/" onDismiss={() => {
      console.log("dismiss");
      onDismiss();
    }} >
      {level1(homePage(), () => url("Branch/dashboard"), [
        level2("Dashboard", () => url("Branch/dashboard")),
        level2("Past Due", () => url("Branch/autopay")),
        level2("Units Grid", () => url("Branch/units")),
        level2("Customers", () => url("Branch/customers")),
        level2("Ledger", () => url("Branch/ledger")),
        level2("Sales Tax", () => url("Branch/sales-tax")),
        level2("Rentals", () => url("Branch/rentals")),
      ])}
      <LevelList table="Customer" />
      <LevelList table="Item" />
      <LevelList table="Promotion" />
      <LevelList table="Branch" />
      <LevelList table="Owner" />
      {isAdmin ? <>
        <Level label="Admin">
          {levelList2("Unit", "Units")}
          {levelList2("UnitType", "Unit Types")}
          {levelList2("User", "Users")}
          {levelList2("Division", "Divisions")}
          {levelList2("NoticeTemplate", "Email Templates")}
          {levelList2("Rental", "Rentals")}
          {level2("System Ledger", () => "/System/ledger")}
        </Level>
        <Level label="Ledgers">
          {LedgerTables.map(e => levelList2(e, e))}
        </Level>
      </> : null}
      {isArlen ? <>
        <Level label="Arlen">
          {level2("Stripe Status", () => `/Arlen/stripe`)}
          {level2("Autopay Status", () => `/Arlen/autopay`)}
          {levelList2("BranchUser", "User Branches")}
          {levelList2("Permission", "Permissions")}
          {levelList2("UserPermission", "User Permissions")}
          {levelList2("Transaction", "Transactions")}
          {level2("Custom", () => `/CustomTable`)}
          {level2("Testing", () => `/Testing`)}
        </Level>
      </> : null}
    </Navigation >
  );

}