import "@angular/compiler";
import awsConfig from './aws-exports.json';
import { Amplify } from 'aws-amplify';
import App from "./react-app";
import { ReactInjectorRouterHack, rootInjector } from "react-utils";
import { Router } from "@angular/router";
import { NgZone } from "@angular/core";



awsConfig.auth.oauth.redirect_sign_in_uri[0] = location.origin + "/";
awsConfig.auth.oauth.redirect_sign_out_uri[0] = location.origin + "/";
Amplify.configure(awsConfig);
console.log(awsConfig);


rootInjector.provide(Router,
  new Proxy(rootInjector, {
    get: (target, prop) => {
      if (prop === "url") return location.pathname + location.search;
      if (prop === "navigateByUrl") return target.get(ReactInjectorRouterHack).navigateByUrl;
      if (prop === "navigate") return target.get(ReactInjectorRouterHack).navigate;
      debugger;
      throw new Error(`Why is router being used for ${String(prop)}?`);
    }
  })
);

rootInjector.provide(NgZone, new Proxy({}, {
  get: (target, prop) => {
    debugger;
    throw new Error("Why is zone being used?");
  }
}))


export default App;