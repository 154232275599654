import { BlockStack, Card, DataTable, Page, Spinner } from "@shopify/polaris/index";
import { DataService } from "data-service";
import { useNavigate } from "react-router";
import { useAngular, useAsyncEffect } from "react-utils";
import { SelectTable, useTableListTree } from "../tables/SelectTable";
import { useCallback, useMemo, useState } from "react";
import { CustomColumnState } from "../tables/CustomColumnState";
import { ObjectPathTree, TABLE_NAMES, TableViewColumn, truthy } from "common";
import { TableView, TableViewClass } from "../tables/table-views";
import { Buffer } from "buffer";

export function uuidFromBase64(id: string): string {
  const hex = Buffer.from(id.replace("-", "+").replace("_", "/") + "==", "base64").toString("hex");
  return [hex.slice(0, 8), hex.slice(8, 12), hex.slice(12, 16), hex.slice(16, 20), hex.slice(20)].join("-");
}

function parseTxnID(txnID: string) {
  //`${new Date().toISOString()}-${uuidBase64(this.user.id)}-${otherType}-${uuidBase64(otherID)}`
  const reg = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)-([A-Za-z0-9_-]{22})-([A-Za-z0-9_-]+)-([A-Za-z0-9_-]{22})$/;
  const match = txnID.match(reg);
  if (!match) return null;
  const [date, user, type, id] = match.slice(1);
  return { date, user: uuidFromBase64(user), type, id: uuidFromBase64(id) };
}
class Col {
  constructor(public name: string, public type: 'text' | 'numeric', public val: string | number | null | undefined) { }
}
export function AppTesting() {
  const { get } = useAngular();
  const data = get(DataService);
  const { loading, result: rows } = useAsyncEffect(async () => {
    const txns = await data.prisma.transaction.findMany({
      // where: {id: "941e49df-4be9-43e6-ace8-8f60a9a87ae5"},
      include: {
        branchDiscountLedgerLine: true,
        branchLedgerLine: true,
        centralDiscountLedgerLine: true,
        centralLedgerLine: true,
        customerLedgerLine: true,
        divisionDiscountLedgerLine: true,
        divisionLedgerLine: true,
        ownerLedgerLine: true,
        salesTaxLedgerLine: true,
        paymentLine: true,
        invoiceLine: true,
      }
    });
    const USE_VALID = true;
    console.log(txns.filter(e => e.VoidSince || e.IS_TESTING).length);

    const rows = txns.filter(e => e.paymentLine && !e.VoidSince && !e.IS_TESTING).map(e => {
      const txn = e.paymentLine?.txnID ? parseTxnID(e.paymentLine.txnID) : null;
      const customer = !!e.customerLedgerLine;
      const branch = !!e.branchLedgerLine;
      const owner = !!e.ownerLedgerLine;
      const division = !!e.divisionLedgerLine;
      const central = !!e.centralLedgerLine;
      const salestax = !!e.salesTaxLedgerLine.length;
      const payment = !!e.paymentLine;
      const invoice = !!e.invoiceLine;
      const ledger = e.paymentLine?.PaymentLedger;
      const [valid, desc] = (() => {

        if (payment && invoice) return [false, "Invoice & Payment"];

        if (payment) {
          const breakout = [branch, owner, division, central, salestax];
          const titles = ["Branch", "Owner", "Division", "Central", "Sales Tax"];
          // DOUBLE ENTRY ACCOUNTING:
          // when the customer makes a payment, the money is recieved into the customer's holding balance
          // then assigned to invoice lines which specify how the money is distributed.
          // it's not exactly double entry and this is where most of the problems will occur.


          const length = breakout.filter(e => e).length;
          const hasTxnID = !!e.paymentLine?.txnID;
          const hasUser = !!txn?.user;

          if (customer && length === 0) return [] // [hasTxnID, "Customer Payment to System"];
          if (customer && length === 1) return [hasUser, "Customer Payment to " + titles[breakout.indexOf(true)]];
          if (customer && length > 1) return [false, "Invalid Payment"];

          if (!customer && length === 1) {
            if (e.branchLedgerLine && e.branchLedgerLine.Amount > 0) return [false, "Branch Payment"];
            if (e.ownerLedgerLine && e.ownerLedgerLine.Amount > 0) return [false, "Owner Payment"];
            if (e.divisionLedgerLine && e.divisionLedgerLine.Amount > 0) return [false, "Division Payment"];
            return [hasTxnID, "Payout to " + titles[breakout.indexOf(true)]];
          }
          if (!customer && length === 0) return [false, "Empty Payout"];
          if (!customer && length > 1) return [false, "Invalid Payout"];
        }

        return [true, "Unknown"];
        // if (e.paymentLine) {
        //   if (e.customerLedgerLine && e.branchLedgerLine) return "Payment to Branch";
        //   if (e.customerLedgerLine && e.ownerLedgerLine) return "Payment to Owner";
        //   if (e.customerLedgerLine && e.divisionLedgerLine) return "Payment to Division";
        //   // why aren't we using central here? That could be super useful, 
        //   // although the balance would always be negative.
        //   if (e.customerLedgerLine) return "Payment to Central";
        //   if (!e.customerLedgerLine && e.branchLedgerLine) return "Payout to Branch";
        //   if (!e.customerLedgerLine && e.ownerLedgerLine) return "Payout to Owner";
        //   if (!e.customerLedgerLine && e.divisionLedgerLine) return "Payout to Division";
        //   if (!e.customerLedgerLine && e.centralLedgerLine) return "Payout to Central";
        // }
        // if (e.invoiceLine) {
        //   if (e.customerLedgerLine && e.branchLedgerLine && !e.ownerLedgerLine) return "Branch Charge";
        //   if (e.customerLedgerLine && e.ownerLedgerLine && e.branchLedgerLine && e.centralLedgerLine) return "Unit Rental";
        // }
      })();



      return (false && valid === true || valid === undefined) ? null : [
        new Col("Date", "text", e.Date),
        new Col("Type", "text", e.invoiceLine ? "Invoice" : e.paymentLine ? "Payment" : "Unknown"),
        new Col("Description", "text", desc),
        new Col("Customer Amount", "numeric", e.customerLedgerLine?.Amount.toString() || ""),
        new Col("Branch Amount", "numeric", e.branchLedgerLine?.Amount.toString() || ""),
        new Col("Owner Amount", "numeric", e.ownerLedgerLine?.Amount.toString() || ""),
        new Col("Division Amount", "numeric", e.divisionLedgerLine?.Amount.toString() || ""),
        new Col("Central Amount", "numeric", e.centralLedgerLine?.Amount.toString() || ""),
        new Col("Payment Status", "text", e.paymentLine?.PaymentStatus || ""),
        // new Col("Txn ID", "text", e.paymentLine?.txnID?.slice(0, 20) || ""),
        new Col("Sales Tax", "numeric", e.salesTaxLedgerLine.length.toString()),
        new Col("Branch Discount", "text", e.branchDiscountLedgerLine ? "true" : "false"),
        // new Col("Description", "text", e.Description),
        new Col("Void", "text", e.VoidSince ? "true" : "false"),
        new Col("Testing", "text", e.IS_TESTING ? "true" : "false"),
        // new Col("ID", "text", e.id),
      ]
    }).filter(truthy);
    console.log(rows);
    return rows;

  });

  return <Page fullWidth>
    <BlockStack gap="400">
      {rows && <DataTable
        hoverable
        columnContentTypes={rows[0].map(e => e.type)}
        headings={rows[0].map(e => e.name)}
        rows={rows.map(e => e.map(e => e.val))}
      />}
    </BlockStack>
  </Page >

}



// function RenderInner({ table, view, rows: rows2 }: { table: TABLE_NAMES | undefined, view: TableView, rows: any[] }) {
//   const navigate = useNavigate();
//   const data = useAngular().get(DataService);
//   const { cols, rows, idcol, value, setValue } = useTableListTree(table, view);
//   const customs = useMemo(() => table ? new CustomColumnState(table, data, cols) : undefined, [table, cols]);
//   if (rows2 !== rows) setValue({ action: "reset", newValue: rows2 });
//   const onSelectRow = useCallback((row: any) => {
//     navigate(`/${row.metadata.PaymentLedger}/edit/${row.metadata.HostID}`);
//   }, [navigate]);
//   return <SelectTable {...{
//     cols, rows, idcol, value, setValue, customs, onClickRow: onSelectRow
//   }} />;
// }



// const STRIPE_LIMIT = 10;
// function useStripeData(data: DataService) {

//   const [options, setOptions] = useState<Stripe.AccountListParams>({ limit: STRIPE_LIMIT });
//   // const [rows, setRows] = useState<Stripe.Account[]>([]);
//   const [offset, setOffset] = useState(0);

//   const view = useMemo(() => TableViewClass.makeClientView(undefined, {
//     AND: [],
//     key: "stripeview",
//     title: "Stripe Accounts List",
//     list: (x: ObjectPathTree<Stripe.Account & { metadata: { PaymentLedger: string, Name: string } }, []>) => [
//       new TableViewColumn({
//         key: x.requirements.eventually_due.__,
//         title: "Fields Due",
//         aggregate: "array",
//         markup: (v: string[] | null | undefined) => {
//           return (
//             <BlockStack gap="0">
//               {v?.map(e => <span>{e}</span>)}
//             </BlockStack>
//             // <Tooltip content={v ? v.join(", ") : ""}>
//             //   <Badge>{v ? v.length.toString() : ""}</Badge>
//             // </Tooltip>
//           );
//         },
//         custom: new StringClass("FieldsDue"),
//       }),
//       new TableViewColumn({
//         key: x.metadata.Name.__,
//         title: "Name",
//         custom: new StringClass("Name"),
//       }),
//       new TableViewColumn({
//         key: x.metadata.PaymentLedger.__,
//         title: "Payment Ledger",
//         custom: new StringClass("PaymentLedger"),
//       }),
//     ],
//   }), []);

//   const { loading, result: rows } = useAsyncEffect(async () => {
//     const res = await data.server.serverStripeAccountList(options);
//     while (res.has_more) {
//       const newrows = await data.server.serverStripeAccountList({ ...options, starting_after: res.data[res.data.length - 1].id });
//       res.data.push(...newrows.data);
//       res.has_more = newrows.has_more;
//     }
//     return res.data;
//   }, undefined, undefined, [options]);

//   return { loading, rows, view };
// }