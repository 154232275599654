import { Dispatch, SetStateAction, useCallback, useId, useMemo, useState } from "react";

import { useAngular, useAsyncEffect } from "react-utils";

import { Autocomplete, BlockStack, Button, Card, Icon, InlineStack, Loading, OptionList, Page, Popover, RadioButton, Text } from "@shopify/polaris";
import { SearchIcon as SearchMajor } from "@shopify/polaris-icons";
import { TABLE_NAMES, proxy, schema, truthy } from "common";
import { DataService } from "data-service";


// setup sections for each desired permission state.
// so for each action level and values we would select all tables that apply
// it would actually be useful to set this up as the users list page and show all users 
// that have the specific permission set. I still have to figure out how to record this information, 
// but allowing a super_admin to set it client side would probably be more useful than doing it server-side.


interface UserPermissionRow {
  action: "read" | "write";
  level: "table" | "groups" | "rows";
  value: string[];
  table: TABLE_NAMES;
  remote: TABLE_NAMES;
}
interface UserPermissionAction {
  table: string,
  level?: "table" | "groups" | "rows" | "none",
  action?: "read" | "write",
  remote?: string;
  value?: string[]
}
class UserPermissionState {
  rows: Map<string, UserPermissionRow>;
  groups;
  groupKeys;
  constructor(init: { perm: { name: string, }, value: string[] }[]) {
    this.rows = new Map(init.map(e => {
      const check = /^(read|write) in ([a-zA-Z0-9_]+) for (table|groups|rows) of ([a-zA-Z0-9_]+)$/.exec(e.perm.name);
      if (check) {
        const [, action, table, level, remote] = check;
        return [table, {
          action: action as "read" | "write",
          table: table as TABLE_NAMES,
          level: level as "table" | "groups" | "rows",
          remote: remote as TABLE_NAMES,
          value: e.value || []
        }];
      } else {
        throw new Error("Invalid permission name");
      }
    }));

    const groups = this.groups = {} as Record<string, string[]>;
    Object.values(schema.tables).forEach((e) => {
      const remote = e.attributes.rls?.first()?.remoteTable;
      if (remote) {
        groups[remote] = groups[remote] || [];
        groups[remote].push(e.name);
      }
      return groups;
    }, {} as any);
    this.groupKeys = Object.keys(this.groups);

    console.log(this);
  }

  update(action: {
    table: string,
    level?: "table" | "groups" | "rows" | "none",
    action?: "read" | "write",
    remote?: string;
    value?: string[]
  }) {
    this.rows.set(action.table, Object.assign({}, this.rows.get(action.table), action));
  }
}


const groupTables: Record<string, string[]> = {};

Object.keys(schema.tables).forEach(table => {
  const item = schema.tables[table].attributes.rls?.first();
  if (!item?.remoteTable) return;
  groupTables[item.remoteTable] = groupTables[item.remoteTable] || [];
  groupTables[item.remoteTable].push(table);
});

const groupKeys: string[] = Object.keys(groupTables);

export function PermissionsPage() {

  const { get } = useAngular();
  const data = get(DataService);

  const rls: { name: string, table: string }[] = [];

  return (
    <Page title="Permissions">
      <Permissions />
    </Page>
  );
}


function Permissions() {
  const { get } = useAngular();
  const data = get(DataService);

  const { result: state } = useAsyncEffect(async () => {
    const res = await data.singleDataQuery(proxy.userPermission.findMany({
      where: {},
      select: {
        user: { select: { id: true, name: true, } },
        perm: { select: { name: true, } }, value: true
      }
    }));
    return new UserPermissionState(res);
  });



  return (
    <BlockStack gap="600">
      <Text as="h2" variant="headingLg">Permissions (saving not implemented)</Text>
      {!state && <Loading />}
      {state && state.groupKeys.map(group => <>
        <Text as="h3" variant="headingMd">{group}</Text>
        <Card><PermissionRow group={group} /></Card>
      </>)}
    </BlockStack>)
}


const usePermissionRow = (group: string) => {

  const [action, setActionState] = useState<"read" | "write">();
  const [level, setLevelState] = useState<"table" | "groups" | "rows" | "none">();
  const [selectedState, setSelectedState] = useState<Record<string, boolean>>({});

  const selected = groupTables[group].filter(e => selectedState[e]);
  const setSelected = useCallback((value: string[]) => {
    const newValue = {} as Record<string, boolean>;
    groupTables[group].forEach(e => { newValue[e] = value.includes(e); });
    setSelectedState(newValue);
  }, [group]);

  const handleActionChangeState = useCallback((checked: boolean, id?: string) => {
    console.log(id);
    setActionState(id as "read" | "write");
  }, []);

  const handleLevelChangeState = useCallback((checked: boolean, id?: string) => {
    console.log(id);
    setLevelState(id as "table" | "groups" | "rows" | "none");
  }, []);

  const handleActionChange = useCallback((checked: boolean, id?: string) => {
    const find = ["read", "write"].find(e => id?.endsWith(e));
    if (!find) return;
    handleActionChangeState(checked, find);
  }, [handleActionChangeState]);

  const handleLevelChange = useCallback((checked: boolean, id?: string) => {
    const find = ["table", "groups", "rows", "none"].find(e => id?.endsWith(e));
    if (!find) return;
    handleLevelChangeState(checked, find);
  }, [handleLevelChangeState]);

  return { selected, action, level, setSelected, handleLevelChange, handleActionChange }

};

function PermissionRow({
  group,
  values = [],
}: {
  group: string;
  values?: string[];
}) {

  const { action, level, selected, setSelected, handleActionChange, handleLevelChange } = usePermissionRow(group);

  const [path, setPath] = useState("");

  const id = useId();
  const name = (action: string, table: string, level: string, remote: string) =>
    `${action} in ${table} for ${level} of ${remote}`;

  return (
    <BlockStack gap="300">
      {action && level && selected.map((e, i) => <Text as="span">{name(action, e, level, group)} matching User:{path}</Text>)}
      <InlineStack gap="300">
        {selected.map((e, i) => (<>
          {(i > 0) && <vr className="Polaris-Divider" />}
          <Text as="span" variant="bodyMd">{e}</Text>
        </>))}
      </InlineStack>
      <InlineStack gap="300">
        <SelectTable options={groupTables[group]} selected={selected} setSelected={setSelected} />
        <vr className="Polaris-Divider" />
        <InlineStack gap="300">{["read", "write"].map(f =>
          <RadioButton
            id={id + f}
            name={id + f}
            label={f}
            checked={action === f}
            onChange={handleActionChange}
          />
        )}</InlineStack>
        <vr className="Polaris-Divider" />
        <InlineStack gap="300">{["table", "groups", "rows", "none"].map(f =>
          <RadioButton
            id={id + f}
            name={id + f}
            label={f}
            checked={level === f}
            onChange={handleLevelChange}
          />
        )}</InlineStack>
      </InlineStack>
      <InlineStack gap="300">
        <Text as="span" variant="bodyMd">Path to {group} ID</Text>
        <SelectField table={group} path={path} setPath={setPath} />
      </InlineStack>
    </BlockStack>
  );
}


function SelectTable({
  options,
  selected,
  setSelected,
}: {
  options: string[];
  selected: string[];
  setSelected: (value: string[]) => void;
}) {

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <Button onClick={toggleActive} disclosure>
      Options
    </Button>
  );


  return (
    <Popover
      active={active}
      activator={activator}
      onClose={toggleActive}
    >
      <OptionList
        title="Tables"
        allowMultiple
        options={options.map(e => ({ value: e, label: e }))}
        selected={selected}
        onChange={setSelected}
      />
    </Popover>
  );
}

function SelectField({ table, path, setPath }: { table: string, path: string, setPath: Dispatch<SetStateAction<string>> }) {
  const itemName = path.split("/").filter(truthy).reduce((n, e) => schema.tables[n]?.fields[e].name, "User");
  const item = schema.tables[itemName];

  const [active, setActive] = useState(false);
  // const [selected, setSelected] = useState<string[]>([]);
  // const options = ;
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  return (<>
    {["User", ...path.split("/")].map(e => <Text as="span">{e}</Text>)}
    {itemName && item && <Popover
      active={active}
      activator={<Button onClick={toggleActive} disclosure>{item.name}</Button>}
      onClose={toggleActive}
    >
      <OptionList
        title={`Fields for ${item.name}`}
        options={Object.entries(item.fields).map(([k, v]) => ({ value: k, label: k }))}
        selected={[]}
        onChange={([value]) => setPath(path => [...path.split("/"), value].join("/"))}
      />
    </Popover>}
    <Button onClick={() => { setPath(path => path.split("/").slice(0, -1).join("/")); }}>Back</Button>
  </>
  );
}


function AutocompleteExample() {
  const deselectedOptions = useMemo(
    () => [
      { value: 'rustic', label: 'Rustic' },
      { value: 'antique', label: 'Antique' },
      { value: 'vinyl', label: 'Vinyl' },
      { value: 'vintage', label: 'Vintage' },
      { value: 'refurbished', label: 'Refurbished' },
    ],
    [],
  );
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(deselectedOptions);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === '') {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex),
      );
      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const updateSelection = useCallback(
    (selected: string[]) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });

      setSelectedOptions(selected);
      setInputValue(selectedValue[0] || '');
    },
    [options],
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label="Tags"
      value={inputValue}
      prefix={<Icon source={SearchMajor} tone="base" />}
      placeholder="Search"
      autoComplete="off"
    />
  );

  return (
    <div style={{ height: '225px' }}>
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={updateSelection}
        textField={textField}
      />
    </div>
  );
}