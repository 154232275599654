import { PrismaQuery } from "common";
import { DataService } from "data-service";
import React from "react";
import { useAngular, useLoadingMarkup } from "react-utils";
import { DataListColumn, FormsQuestionService } from "../utils";
import { CustomTable, useTableData } from "../tables/useCustomTable";
import { Banner } from "@shopify/polaris";

const tabLabels = ["All", "Ready", "Failed", "Attempts"] as const;
const tabEnum = Object.fromEntries(new Map(tabLabels.map((e, i) => [e, i]))) as Record<typeof tabLabels[number], number>;

/** curTab needs to be keyed */
export function AutopayTable({ curBranch, curTab, setTab, showFilters }: { curBranch: string | undefined, curTab: number, setTab?: React.Dispatch<React.SetStateAction<number>>, showFilters?: boolean }) {
  const { get } = useAngular();
  const data = get(DataService);
  const fq = get(FormsQuestionService);

  const loadingMarkup = useLoadingMarkup("customer ledger lines");
  const emptyMarkup = () => <Banner title="No Unpaid Fees" tone="success" />;
  const tableData = curTab === tabEnum.Attempts ? useTableData(() => [
    { key: "Date", title: "Autopay Date", sort: -1, calculate: e => e.date, },
    { key: "invoiceDate", title: "Invoice Date", calculate: e => e.invoiceLine.line.Date, },
    {
      key: "name", title: "Customer", queryColumn: true,
      calculate: e => e.paymentLine?.line.customerLedgerLine?.customer.billing?.Name,
      link: e => `/Customer/edit/${e.paymentLine?.line.customerLedgerLine?.customer.id}`
    },
    {
      key: "Amount", title: "Amount", filterType: "currency",
      calculate: e => e.paymentLine?.line.customerLedgerLine?.Amount,
    },
    // { 
    //   // this should probably go in an errors table
    //   key: "SameCustomer", title: "Same Customer", filterType: "boolean",
    //   calculate: e => e.invoiceLine.rental.customer?.id === e.paymentLine?.line.customerLedgerLine?.customer.id,
    // },
    {
      key: "PaymentStatus", title: "Payment Status",
      calculate: e => e.paymentLine?.PaymentStatus,
      schemaType: DataListColumn.getFieldType("AutopayAttempt", x => x.paymentLine.PaymentStatus.__),
    },
  ], [], async () => {
    return await data.prisma.autopayAttempt.findMany({
      select: PrismaQuery.selectPathsProxy("AutopayAttempt", x => [
        x.id.__,
        x.date.__,
        x.invoiceLine.line.Date.__,
        x.paymentLine.id.__,
        x.paymentLine.PaymentStatus.__,
        x.paymentLine.line.customerLedgerLine.customer.id.__,
        x.paymentLine.line.customerLedgerLine.customer.billing.Name.__,
        x.paymentLine.line.customerLedgerLine.Amount.__,
      ] as const),
    });
  }, [curTab, curBranch], "id") : useTableData(() => [
    { key: "Date", title: "Date", calculate: e => e.Date, sort: -1 },
    { 
      key: "name", title: "Customer", queryColumn: true, 
      calculate: e => e.customerBilling?.Name, 
      link: e => `/Customer/edit/${e.customerID}` 
    },
    { key: "email", title: "Email", queryColumn: true, calculate: e => e.customerEmail },
    { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
    { key: "unit", title: "Unit", calculate: e => e.rental.unit.Name, link: e => e.rental.unit.id && `/Unit/edit/${e.rental.unit.id}` },
    { key: "branch", title: "Branch", calculate: e => e.rental.unit.currentBranch.DisplayName },
    { key: "customerReady", title: "Customer Ready", filterType: "boolean", calculate: e => e.customerReady },
    { key: "customerAutopay", title: "Customer Autopay", filterType: "boolean", calculate: e => e.customerAutoPay },
    { key: "shouldAutopay", title: "Should Autopay", filterType: "boolean", calculate: e => e.shouldAutopay },
    { key: "hasOldLines", title: "Old Lines Paid", filterType: "boolean", calculate: e => e.oldLinesPaid },
    { key: "autopayAttempts", title: "Autopay Attempts", filterType: "numeric", calculate: e => e.autopayAttempts },
    { key: "customerBalance", title: "Customer Balance", filterType: "currency", calculate: e => e.customerBalance },
  ], [], async () => {

    let newValue = await data.server.queryGetAutopayForCustomerLedger({
      AND: [{
        line: {
          VoidSince: null,
          OR: [
            { invoiceLine: { paidOn: null, branchID: curBranch || undefined } },
            { paymentLine: { PaymentStatus: { not: "Cleared" } } },
          ]
        }
      }]
    });

    switch (curTab) {
      case tabEnum.All:
        return newValue;
      case tabEnum.Failed:
        return newValue.filter(e => {
          // if this is in the future, this isn't failed
          if (e.isFuture) return false;
          // if we will attempt it, this isn't failed
          if (e.attemptAutopay) return false;
          // if autopay succeeded, this isn't failed
          if (e.autopayAttempted && e.autopayAttemptsStatus.some(e => e && e.inArray(["Approved", "Cleared"]))) return false;
          // autopay failed or won't be tried
          if (!e.shouldAutopay || e.autopayAttempted || e.isOld || !e.customerAutoPay || !e.customerReady || !e.oldLinesPaid) return true;
          debugger;
          return false;
        });
      case tabEnum.Ready:
        return newValue.filter(e => e.attemptAutopay);
      default:
        throw new Error("Invalid tab");
    }

  }, [curTab, curBranch], "id");


  return <CustomTable
    tableData={tableData}
    curTab={curTab}
    setTab={setTab}
    showFilters={showFilters}
    tabLabels={setTab ? tabLabels : undefined}
    emptyMarkup={emptyMarkup}
    loadingMarkup={loadingMarkup}
    resourceName={{ singular: "Customer Invoice Line", plural: "Customer Invoice Lines" }}
    pagination
  />;

}
AutopayTable.tabLabels = tabLabels;

AutopayTable.tabEnum = tabEnum;
