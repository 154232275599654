import React from "react";
import { Autocomplete, BlockStack, ChoiceList, ChoiceListProps, Text, TextField } from "@shopify/polaris";
import { useCallback, useReducer, useRef, useState } from "react";
import { ChoiceListInlineStack } from "./fields";

export function reducerKeyValueMap<K extends string, V>(state: Record<K, V>, action: { key: K; value: V; } | "clear") {
  if (action === "clear") return {};
  const { key, value } = action;
  return { ...state, [key]: value };
}

/** Do not memoize this class. There are values which update on every render. */
export class useSimpleValueFields<T extends { readonly [K: string]: string; }> {

  public curValue: T;
  public changedFields: { readonly [key: string]: boolean; };
  public blurredFields: { readonly [key: string]: boolean; };
  public setValue: React.Dispatch<React.SetStateAction<T>>;
  public setChangedFields: React.Dispatch<{ key: string & keyof T; value: boolean; } | "clear">;
  public setBlurredFields: React.Dispatch<{ key: string & keyof T; value: boolean; } | "clear">;

  valid = true;
  constructor(
    public initValue: T,
    public editing: boolean,
  ) {

    const [curValue, setValue] = useState(initValue);
    this.curValue = curValue;
    this.setValue = setValue;

    const [curChangedFields, setChangedFields] = useReducer(reducerKeyValueMap<string, boolean>, {});
    this.changedFields = curChangedFields;
    this.setChangedFields = setChangedFields;

    const [curBlurredFields, setBlurredFields] = useReducer(reducerKeyValueMap<string, boolean>, {});
    this.blurredFields = curBlurredFields;
    this.setBlurredFields = setBlurredFields;

  }
  clear = () => {
    this.setValue(this.initValue);
    this.setChangedFields("clear");
    this.setBlurredFields("clear");
  }
  checkValid(validate: ((value: T) => string | undefined) | undefined, required: boolean | undefined, key: string, title: string) {
    if (!this.blurredFields[key]) { return; }
    if (required && !this.curValue[key]) {
      this.valid = false;
      return `${title} is required`;
    }
    const error = validate?.(this.curValue);
    if (error)
      this.valid = false;
    return error;
  }

  TextField = ({ key, title, onChangeMap, validate, required }: {
    key: string & keyof T;
    title: string;
    onChangeMap?: (input: string) => string;
    validate?: (value: T) => string | undefined;
    required?: boolean;
  }) => {
    if (!this.editing)
      return (
        <BlockStack key={key} gap="100">
          <Text as="h4" variant="headingMd">{title}</Text>
          <Text as="span" variant="bodyMd">{this.curValue[key]}</Text>
        </BlockStack>
      );

    return (
      <TextField
        requiredIndicator={required}
        key={key}
        autoComplete={key}
        label={title}
        value={this.curValue[key]}
        onChange={(value) => {
          this.setChangedFields({ key, value: true });
          this.setValue(curValue => ({ ...curValue, [key]: onChangeMap ? onChangeMap(value) : value }));
        }}
        onBlur={() => { this.setBlurredFields({ key, value: true }); }}
        error={this.checkValid(validate, required, key, title)} />
    );

  };
  ChoiceList = ({ key, title, choices, validate, required }: { key: string & keyof T; title: string; choices: ChoiceListProps["choices"]; validate?: (value: T) => string | undefined; required?: boolean; }) => {

    if (!this.editing)
      return (
        <BlockStack key={key} gap="100">
          <Text as="h4" variant="headingMd">{title}</Text>
          <Text as="span" variant="bodyMd">{this.curValue[key]}</Text>
        </BlockStack>
      );

    this.checkValid(validate, required, key, title);

    return (
      <ChoiceList
        key={key}
        title={title + (required ? " *" : "")}
        titleHidden={!title}
        choices={choices}
        selected={[this.curValue[key]]}
        onChange={(value) => {
          this.setChangedFields({ key, value: true });
          this.setValue(curValue => ({ ...curValue, [key]: value[0] }));
        }} />
    );

  };
  ChoiceListInline = ({ key, title, choices, validate, required }: { key: string & keyof T; title: string; choices: ChoiceListProps["choices"]; validate?: (value: T) => string | undefined; required?: boolean; }) => {
    const currentLabel = choices.find(e => e.value === this.curValue[key])?.label;

    if (!this.editing)
      return (
        <BlockStack key={key} gap="100">
          <Text as="h4" variant="headingMd">{title}</Text>
          <Text as="span" variant="bodyMd">{currentLabel}</Text>
        </BlockStack>
      );

    this.checkValid(validate, required, key, title);

    return (
      <ChoiceListInlineStack
        key={key}
        title={title + (required ? " *" : "")}
        choices={choices}
        selected={[this.curValue[key]]}
        onChange={(value) => {
          this.setChangedFields({ key, value: true });
          this.setValue(curValue => ({ ...curValue, [key]: value[0] }));
        }} />
    );
  };

  AddressLookup = ({ key, title, onSearch, validate, required }: { key: string & keyof T; title: string; onSearch: (value: string) => Promise<{ value: string; label: string; }[]>; validate?: (value: T) => string | undefined; required?: boolean; }) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    // const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<{ label: string; value: string; }[]>([]);
    const [loading, setLoading] = useState(false);
    const timeout = useRef<any>();

    const onChangeField = useCallback((value: string) => {
      if (loading)
        return;
      this.setChangedFields({ key, value: true });
      this.setValue(curValue => ({ ...curValue, [key]: value }));
      if (timeout.current)
        clearTimeout(timeout.current);
      timeout.current = setTimeout(async () => {
        timeout.current = undefined;
        setLoading(true);
        setOptions(await onSearch(value));
        setLoading(false);
      }, 500);
    }, [loading, onSearch, key]);

    const onSelectResult = useCallback(([selected]: string[]) => {
      // console.log(selected);
      // setInputValue(selected);
      this.setValue(curValue => ({ ...curValue, [key]: selected }));
    }, [key]);

    if (!this.editing)
      return (
        <BlockStack key={key} gap="100">
          <Text as="h4" variant="headingMd">{title}</Text>
          <Text as="span" variant="bodyMd">{this.curValue[key]}</Text>
        </BlockStack>
      );

    return <Autocomplete
      key={key}
      options={options}
      selected={selectedOptions}
      onSelect={onSelectResult}
      loading={loading}
      textField={<Autocomplete.TextField
        requiredIndicator={required}
        error={this.checkValid(validate, required, key, title)}
        label={title}
        value={this.curValue[key]}
        onChange={onChangeField}
        onBlur={() => { this.setBlurredFields({ key, value: true }); }}
        placeholder="Search"
        autoComplete="off" />} />;
  };
}
