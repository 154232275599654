import {
  BlockStack, Card, Page, Spinner
} from "@shopify/polaris";
import { FieldClass, ObjectPathTree, String, TABLE_NAMES, TableViewColumn } from "common";
import { DataService } from "data-service";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useAngular, useAsyncEffect } from "react-utils";
import Stripe from "stripe";
import { SelectTable, useTableListTree } from "../tables/SelectTable";
import { TableView, TableViewClass } from "../tables/table-views";
import { CustomColumnState } from "../tables/CustomColumnState";

function RenderInner({ table, view, rows: rows2 }: { table: TABLE_NAMES | undefined, view: TableView, rows: any[] }) {
  const navigate = useNavigate();
  const data = useAngular().get(DataService);
  const { cols, rows, idcol, value, setValue } = useTableListTree(table, view);
  const customs = useMemo(() => table ? new CustomColumnState(table, data, cols) : undefined, [table, cols]);
  if (rows2 !== rows) setValue({ action: "reset", newValue: rows2 });
  const onSelectRow = useCallback((row: any) => {
    navigate(`/${row.metadata.PaymentLedger}/edit/${row.metadata.HostID}`);
  }, [navigate]);
  return <SelectTable {...{
    cols, rows, idcol, value, setValue, customs, onClickRow: onSelectRow
  }} />;
}
let fieldindex = 0;
class StringClass extends FieldClass { constructor(key: string) { super(key, fieldindex++, new String(), false, false); } }



const STRIPE_LIMIT = 10;
function useStripeData(data: DataService) {

  const [options, setOptions] = useState<Stripe.AccountListParams>({ limit: STRIPE_LIMIT });
  // const [rows, setRows] = useState<Stripe.Account[]>([]);
  const [offset, setOffset] = useState(0);

  const view = useMemo(() => TableViewClass.makeClientView(undefined, {
    AND: [],
    key: "stripeview",
    title: "Stripe Accounts List",
    list: (x: ObjectPathTree<Stripe.Account & { metadata: { PaymentLedger: string, Name: string } }, []>) => [
      new TableViewColumn({
        key: x.requirements.eventually_due.__.key,
        title: "Fields Due",
        aggregate: "array",
        markup: (v: string[] | null | undefined) => {
          return (
            <BlockStack gap="0">
              {v?.map(e => <span>{e}</span>)}
            </BlockStack>
          );
        },
        custom: new StringClass("FieldsDue"),
      }),
      new TableViewColumn({
        key: x.metadata.Name.__.key,
        title: "Name",
        custom: new StringClass("Name"),
      }),
      new TableViewColumn({
        key: x.metadata.PaymentLedger.__.key,
        title: "Payment Ledger",
        custom: new StringClass("PaymentLedger"),
      }),
    ],
  }), []);

  const { loading, result: rows } = useAsyncEffect(async () => {
    const res = await data.server.serverStripeAccountList(options);
    while (res.has_more) {
      const newrows = await data.server.serverStripeAccountList({ ...options, starting_after: res.data[res.data.length - 1].id });
      res.data.push(...newrows.data);
      res.has_more = newrows.has_more;
    }
    return res.data.filter(e =>
      e.requirements?.eventually_due?.length
      && !e.requirements.eventually_due.some(e => e.startsWith("tos_acceptance"))
    );
  }, undefined, undefined, [options]);

  return { loading, rows, view };
}


export function StripeArlenPage() {
  const { get } = useAngular();
  const data = get(DataService);
  const { loading, rows, view } = useStripeData(data);

  return <Page>
    <BlockStack gap="400">
      <Card>
        {!loading && rows && <RenderInner table={undefined} view={view} rows={rows} />}
        {loading && <Spinner />}
      </Card>
    </BlockStack>
  </Page >

}
