import { PropsWithChildren } from 'react';
import { BlockStack, Box, Button, Card, InlineGrid, InlineStack, Text, ButtonProps, Grid, GridProps, BlockStackProps } from '@shopify/polaris';
import React from "react";

export function Card2({ columns, children }: PropsWithChildren<{ columns: GridProps["columns"]; }>) {
  return (
    <Card roundedAbove="sm">
      <Grid columns={columns}>
        {children}
      </Grid>
    </Card>
  );
}
Card2.Title = function Card2Title({ title, action }: { title?: string; action?: ButtonProps; }) {
  return (
    <BlockStack gap="0">
      {action && <InlineStack align="end"><Button variant="plain" {...action} /></InlineStack>}
      {title && <Text as="h2" variant="headingMd" alignment='center'>{title}</Text>}
    </BlockStack>
  );
};
Card2.Section = function Card2Section({ title, action, gap = "100", children }: PropsWithChildren<{ title?: string; action?: ButtonProps; gap?: BlockStackProps["gap"]; }>) {
  return (
    <Box paddingBlock="200">
      <BlockStack gap="200">
        <InlineGrid columns="1fr auto">
          {title && <Text as="h3" variant="headingSm">{title}</Text>}
          {action && <Button variant="plain" {...action} />}
        </InlineGrid>
        <BlockStack gap={gap}>
          {children}
        </BlockStack>
      </BlockStack>
    </Box>
  );
};
