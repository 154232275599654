import { EventEmitter, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ActionList, ActionMenu, Badge, Banner, BlockStack, Box, Button, ButtonGroup, Card, Divider, Grid, InlineStack, MenuActionDescriptor, MenuGroupDescriptor, Modal, ModalProps, Page, Popover, Spinner, Text, TextProps, Tooltip, useBreakpoints } from '@shopify/polaris';
import { EditIcon as EditMajor } from '@shopify/polaris-icons';
import { PipProps } from '@shopify/polaris/components/Badge/components';
import { DataListResult, FEATURE_FLAG_PRICE_OVERRIDE, FEATURE_FLAG_RENT_TO_OWN, PrismaQuery, Rental, Root, SPPI, SPPTypeTree, SelectTypeTree, TABLE_NAMES, TYPE_NAMES, TableViewColumn, ToSelectObject, is, ok } from "common";
import { DataService } from "data-service";
import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { ButtonAwait, NgContextProvider, PageSidebarStyles, Pip, Tone, emitGlobalRefresh, globalMessage, useAngular, useAsyncEffect, useBind, useForward, useLoadingMarkup, useObservable, useObserver, useRefresh } from "react-utils";
import { DataListColumn, FormsQuestionService, QuestionGroup, QuestionRender, QuestionSimple, RentalListState, showPageEditModal, TypedQuestionGroup, UIService } from '../utils';

import { IndexTable, IndexTableHeading } from '@shopify/polaris/components/IndexTable';
import { DisableableAction, LoadableAction, NonEmptyArray } from '@shopify/polaris/types';
import { useI18n } from '@shopify/polaris/utilities/i18n';
import Dinero from "dinero.js";
import pluralize from 'pluralize';
import { Prisma, PrismaClient, PrismaPromise, RentalStatus } from 'prisma-client';
import { useRef, useState, useSyncExternalStore } from 'react';
import { useNavigate, useParams } from 'react-router';
import { firstValueFrom } from 'rxjs';
import { CardTitle } from "react-utils";
import { QuestionItem } from '../components/QuestionItem';
import { PaymentEventsContext, usePaymentProcessor, usePaymentsModal } from '../payments/ProvidePaymentProcessor';
import { useTableListInner, useTableListSimple } from '../tables/TableListInner';
import { ListValue, PrismaWhereQuery, TableView, ledgerTableColumns, ledgerTableViews } from '../tables/table-views';
import { DataPage, TablePageCustomerAdapter } from '../utils';
import { Card2 } from './Card2';
import DineroFactory from 'dinero.js';
import { valMarkup } from '../tables/valMarkup';
import { SpinnerBlock, useSortOpts } from '../tables/SelectTable';
import { renderGroupControls, renderQuestionItem } from '../components/QuestionForm';
import { AuthService } from '../utils/auth.service';
import { dollarsToCents } from './page-table-list';
// import { nth } from 'lodash';

function nthSuffix(n: number) {
  if (typeof n !== "number") n = +n;
  if (Number.isNaN(n)) return n;
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const fromcents = (e: number | null | undefined) => typeof e === "number" ? `$${(+`${e}e-2`).toFixed(2)}` : "---";

export function CustomerLayoutPage() {

  const { id } = useParams();
  ok(id);

  const { injector } = useAngular();
  const state = useMemo(() => new TablePageCustomerAdapter(injector), [injector]);
  useLayoutEffect(() => { state.handleRouteUrl({ table: "Customer", view: "edit", id }); }, [state, id]);
  console.log(state);
  ok(state instanceof TablePageCustomerAdapter);
  useObservable(state.valueChange);
  useObservable(state.loadingChange);


  const onBackAction = useCallback(() => { state.fq.showList("Customer", {}); }, [state.fq]);

  const balance = useObservable(state.balance, null);
  const payments = usePaymentProcessor({ PaymentLedger: "Customer", id, balance: Dinero({ amount: balance ?? 0 }) });

  if (!state.value) return (
    <Page
      key="loading"
      backAction={{ content: "Back", onAction: onBackAction }}
      titleMetadata={<Text as="h3">Customer...</Text>}
    >
      <Card>
        <Spinner />
      </Card>
    </Page>
  );

  return (
    <NgContextProvider injector={injector}>
      <PaymentEventsContext.Provider value={{ paymentMode: payments.events, paymentState: payments.state }}>
        <CustomerLayoutPageInner state={state} balance={fromcents(balance)} />
        {payments.paymentModalsMarkup}
      </PaymentEventsContext.Provider>
    </NgContextProvider>
  );
}

export function CustomerLayoutPageInner({ state, balance }: { state: TablePageCustomerAdapter; balance: string; }) {

  const { fq, data, router, zone, rentalHelper } = state;
  const auth = useAngular().get(AuthService);
  ok(state.value);

  const address = state.value.billing?.Address?.description;
  const name = state.value.billing?.Name ?? "";
  const isTestCustomer = state.value.IS_TESTING;
  const StorageAgreementComplete = state.value.StorageAgreementCompleted;
  const EmailVerified = state.value.EmailVerified;
  const PaymentInfoValid = state.value.PaymentInfoValid;

  const NameIsValid = name?.trim().length > 0;

  const steps = [
    !!EmailVerified,
    !!NameIsValid,
    !!StorageAgreementComplete,
    !!PaymentInfoValid,
  ];

  console.log(steps);

  const showStep = (step: number) => steps.indexOf(false) === step;

  const actiongroups: MenuGroupDescriptor[] = [];

  if (data.status.isArlen) {
    actiongroups.push({
      title: "Dev Actions",
      actions: [{
        content: "Delete Customer",
        onAction: async () => {
          await fq.messageConfirmation("Delete Customer?", "Are you sure you want to delete this customer? This can only be done if there are no rentals or ledger lines. This cannot be undone.", async () => {
            await data.server3("serverDeleteCustomer")({ customerID: state.id });
            await router.navigateByUrl("/Customer/list");
          });
        }
      }, {
        content: "Credit Customer",
        onAction: async () => {
          const amount = window.prompt("Enter the amount to refund IN DOLLARS (0.00)");
          if (!amount) return;
          await data.server.serverCreditCustomer({ customerID: state.id, amount: +dollarsToCents(amount) });
          emitGlobalRefresh();
        }
      }]
    })
  }


  const secondaryActions: MenuActionDescriptor[] = [];

  const [refreshLoading, setRefreshLoading] = useState(false);

  secondaryActions.push({
    content: "Refresh",
    loading: refreshLoading,
    onAction: async () => {
      const customerID = state.id;
      ok(customerID);
      setRefreshLoading(true);
      await data.server.serverSyncPaymentLinesCommitAndChain({ customerID });
      onRefresh?.emit({});
      setRefreshLoading(false);
    }
  })

  const onRefresh = useRefresh();
  const { paymentMode: payments } = usePaymentsModal();

  useForward(state.onSaveSuccess, onRefresh);

  useObserver(payments, e => { if (e === "make-payment-success" || e === "update-payment-success") onRefresh.emit({}); });

  useObserver(onRefresh, () => { state.pageSetupCallback(true); });

  const onBackAction = useCallback(() => { fq.showList("Customer", {}); }, [fq]);
  const onClickScheduleUnit = useBind(rentalHelper.onClickScheduleUnit, rentalHelper, state.id, false);

  const { lgDown, xlUp: twoColumns } = useBreakpoints();

  const styles = PageSidebarStyles(twoColumns);

  const actionGettingStarted: DisableableAction & LoadableAction = {
    content: "View Customer Portal",
    onAction: () => {
      auth.openCustomerPortal(state.id).catch(e => console.log(e));
    }
  }


  return <Page
    key={`loaded-${state.id}`}
    backAction={{ content: "Back", onAction: onBackAction }}
    titleMetadata={<Text key={name} as="h3">{name}</Text>}
    subtitle={address}
    compactTitle={true}
    primaryAction={<Button variant='primary' onClick={onClickScheduleUnit}>Schedule Rental</Button>}
    secondaryActions={<ActionMenu actions={secondaryActions} groups={actiongroups} rollup={lgDown} />}
    additionalMetadata={`Balance: ${balance}`}
    fullWidth
  >
    <NgContextProvider injector={state.injector}>
      <Box paddingBlock="600">
        <BlockStack gap="500">
          {!!isTestCustomer && <Banner title="Test Customer - Ledger and Balance are ignored" tone="warning" />}

          {state.loading ? <Spinner /> : null}
          <div style={styles.wrapper}>
            <div style={styles.cards}>
              <CustomerCustomCards state={state} />
            </div>
            <div style={styles.tables}>
              {showStep(0) && <Banner title="Getting Started: Verify Customer Email" action={actionGettingStarted}>
                <p>The customer should find an email with a link to click on to verify their email address.</p>
                <p>You will need to give them the password you created.</p>
                <p>They can change the password if they want.</p>
              </Banner>}
              {showStep(1) && <Banner title="Getting Started: Enter Basic Info" action={actionGettingStarted}>
                <p>The customer's name is required. Please enter the customer's name in the contact info.</p>
                <p>The customer is also being prompted to enter their contact info.</p>
              </Banner>}
              {showStep(2) && <Banner title="Getting Started: Sign Storage Agreement" action={actionGettingStarted}>
                <p>The customer should see a link which says "Click here to sign your storage agreement".</p>
                <p>Clicking the link will either send them another email from us or it will take them directly to the storage agreement page.</p>
                <p>If they can't find the second email, they can also click on the verify email link again, and then sign the storage agreement from there.</p>
              </Banner>}
              {showStep(3) && <Banner title="Getting Started: Enter Payment Info" action={actionGettingStarted}>
                <p>The customer is ready to enter their payment info.</p>
              </Banner>}
              <CustomerUnpaidTable customerID={state.id} state={state} />
              <RentalTable customerID={state.id} balance={balance} />
              <CustomerLedger customerID={state.id} />
            </div>
          </div>
        </BlockStack>
      </Box>
    </NgContextProvider>
  </Page >;
}



function CustomerCustomCards({ state }: { state: TablePageCustomerAdapter; }) {
  const { get, injector } = useAngular();
  ok(state.value);
  const zone = get(NgZone);
  const [email, signed, payment] = state.customerStatusDisplay;
  const [profile, extra] = state.customerInfoDisplay;
  const billing = (key: keyof typeof profile) => <Text as="p" variant="bodyLg">{profile[key](state.value)}</Text>;
  const exemptTax = state.value.billing?.TaxExempt;
  const exemptLateFee = state.value.billing?.LateFeeExempt;
  const commercial = extra["Customer Type"](state.value) === "Commercial";

  const { xlUp: divider } = useBreakpoints();


  const { paymentMode: payments, paymentState } = usePaymentsModal();
  const onClickEditEmail = useBind(onClickAccountInfo, null, injector, state.id);
  // const onClickEditEmail = useBind(state.onClickEditEmail, state);
  const onClickSetPassword = useBind(state.onClickSetPassword, state);
  const onClickPaymentDetails = () => { payments.emit("update-payment"); }
  const onClickChargePayment = () => { payments.emit("make-payment"); }
  const onClickEditOtherContacts = useBind(state.onClickEditOtherContacts, state);
  const onClickEditCustomerBilling = useBind(state.onClickEditCustomerBilling, state);
  const onClickViewEmails = useBind(state.onClickViewCustomerEmails, state);
  const onClickVerifyEmail = useBind(state.onClickVerifyEmail, state, state.id);
  const onClickCheckStorageAgreement = useBind(state.onClickCheckStorageAgreement, state, state.id);
  const { ccExpiry, ccNumber, ckAccount, ckRouting } = paymentState?.status || {}


  return (
    <>
      <Card roundedAbove="sm">
        <Grid columns={{ xs: 2, lg: 4, xl: 1 }} gap={{ xl: "0" }}>
          <Grid.Cell>
            <Card2.Title title={profile.Name(state.value)} />
            <Card2.Section>
              {status(state, email)}
              {status(state, signed)}
              {status(state, payment)}
            </Card2.Section>
            {/* {divider && <Divider />} */}
          </Grid.Cell>
          <Grid.Cell>
            <Card2.Section title="Contact Info" action={{ icon: EditMajor, onClick: onClickEditCustomerBilling }} >
              {/* {billing("Name")} */}
              {billing("Address")}
              {billing("Phone")}
              {billing("Email")}
              <InlineStack><Button variant="plain" onClick={onClickEditOtherContacts}>Other Contacts</Button></InlineStack>
            </Card2.Section>
            <Card2.Section title="Notes">
              {billing("Notes")}
            </Card2.Section>

            {/* {divider && <Divider />} */}
          </Grid.Cell>
          <Grid.Cell column={{ xs: "2", lg: "3", xl: "1" }} row={{ xs: "2", lg: "1", xl: "3" }}>
            <Card2.Section>
              <PipText progress={commercial ? "complete" : "incomplete"}>
                <Text as="span" variant="bodyMd">{extra["Customer Type"](state.value) + " customer"}</Text>
                {commercial
                  ? <Text as="span" variant="bodyMd">Billed on the {nthSuffix(+extra['Billing Day'](state.value))} day of the month</Text>
                  : <Text as="span" variant="bodyMd">Billed per Start Date of each rental</Text>
                }
              </PipText>
              <PipText progress={exemptTax ? "complete" : "incomplete"}>
                <Text as="span" variant="bodyMd">{exemptTax ? "Tax Exempt" : "Tax not Exempt"}</Text>
              </PipText>
              <PipText progress={exemptLateFee ? "complete" : "incomplete"}>
                <Text as="span" variant="bodyMd">{exemptLateFee ? "Late Fee Exempt" : "Late Fee not Exempt"}</Text>
              </PipText>
            </Card2.Section>

          </Grid.Cell>
          <Grid.Cell column={{ xs: "1", lg: "4", xl: "1" }} row={{ xs: "2", lg: "1", xl: "4" }}>
            <Card2.Section gap="200">
              <InlineStack><Button variant="plain" onClick={onClickEditEmail}>Account Info</Button></InlineStack>
              <InlineStack><Button variant="plain" onClick={onClickSetPassword}>Set Password</Button></InlineStack>
              <InlineStack><Button variant="plain" onClick={onClickPaymentDetails}>Payment Info</Button></InlineStack>
              <InlineStack><Button variant="plain" onClick={onClickViewEmails}>View Emails</Button></InlineStack>
              <InlineStack><Button variant="plain" onClick={onClickVerifyEmail}>Send Verification Email Again</Button></InlineStack>
              <InlineStack><Button variant="plain" onClick={onClickCheckStorageAgreement}>Check Storage Agreement</Button></InlineStack>
            </Card2.Section>
            <Card2.Section title="Payment Info">
              {<Text as="p" variant="bodyLg">{ccNumber}</Text>}
              {<Text as="p" variant="bodyLg">{ccExpiry}</Text>}
              {<Text as="p" variant="bodyLg">{ckRouting}</Text>}
              {<Text as="p" variant="bodyLg">{ckAccount}</Text>}
            </Card2.Section>
          </Grid.Cell>
        </Grid>
      </Card>
    </>
  )


}
function status(
  state: TablePageCustomerAdapter,
  getValue: (row: TablePageCustomerAdapter["value"]) => [string, "error" | "info" | "warn" | "success", Tone]
) {
  const [label, prime, polaris] = getValue(state.value);
  const progress = polaris === "success" ? "complete" : "incomplete";
  return <Badge size="large" progress={progress} tone={polaris}>{label}</Badge>;
  // return <PipText progress={progress} tone={polaris}><Text as="span" variant="bodyMd">{label}</Text></PipText>;
}

function onClickAccountInfo(injector: Injector, customerID: string) {

  showPageEditModal({
    table: "Customer",
    id: customerID,
    group: () => {
      const group = new QuestionGroup({
        __typename: "Customer",
        controls: {
          AWSID: new QuestionSimple("InputText", {
            title: "AWS ID",
            readonly: true,
          }),
          StorageAgreementEnvelopeID: new QuestionSimple("InputText", {
            title: "Storage Agreement Envelope ID",
            readonly: true,
          }),
          StorageAgreementIsTesting: new QuestionSimple("Hidden", {}),
          StorageAgreementCompleted: new QuestionSimple("CheckBox", {
            title: "Is Storage Agreement Signed",
          }),
          IS_TESTING: new QuestionSimple("CheckBox", {
            title: "Is Test Customer"
          }),
        }
      })

      return group;
    },
    useTitle: (page) => {
      return "Edit Rental";
    },
    useWhenLoaded: (page, onClose) => {
      const { StorageAgreementIsTesting } = page.group.form.value;
      const data = injector.get(DataService);
      const auth = injector.get(AuthService);
      useObservable(page.group.form.valueChanges);
      const renderControl = (key: keyof typeof page.group.controls) => renderQuestionItem(page.group.controls[key], "UPDATE", !page.group.noHiddenAnimation);
      console.log(page);
      return <BlockStack gap="400">
        {renderControl("AWSID")}
        {renderControl("StorageAgreementEnvelopeID")}
        {StorageAgreementIsTesting && <ButtonAwait onClick={async () => {
          await data.server.serverDeleteStorageAgreement({ customerID });
        }}>Delete Test Storage Agreement</ButtonAwait>}
        {renderControl("StorageAgreementCompleted")}
        {renderControl("IS_TESTING")}
        <ButtonAwait onClick={async () => {
          // "x-cubes-branch-token" is the branch user id-token and is returned by getAuthToken if it exists
          // "x-cubes-as-customer" is the customer table id and reconfigures everything else
          await auth.openCustomerPortal(customerID);
        }}>View Customer Page</ButtonAwait>
      </BlockStack>
    },
    onSaveValue: async (page, value) => {

      const { IS_TESTING, StorageAgreementCompleted } = value;
      // ok(id);
      // ok(Email);

      const emailUpdated = await page.data.server.serverEditCustomer({
        id: customerID,
        IS_TESTING: IS_TESTING ?? false,
        ...page.data.userRole === "web_admin" ? {
          StorageAgreementCompleted: StorageAgreementCompleted ?? false,
        } : {},
      });
      if (emailUpdated === null) {
        globalMessage.add({
          severity: 'info',
          summary: "Nothing Changed",
          detail: "The Email address does not appear to have changed. Nothing was updated.",
          life: 10000
        });
      } else if (emailUpdated === false) {
        globalMessage.add({
          severity: 'warn',
          summary: "Email Failed to Send",
          detail: "The email address was changed, but the verification email failed for an unknown reason. It has been logged."
        });
      } else if (emailUpdated === true) {
        globalMessage.add({
          severity: 'success',
          summary: "Email Updated",
          detail: "The email address was changed, and we sent an email to the new address."
        });
      }

      page.onSaveSuccess.emit({});
      page.pageSetup(true);
    }

  });

}

export interface CustomerTablesProps {
  customerID: string;
  hideCustomerLedger?: boolean;
  onRefresh?: EventEmitter<object>;
}


export function CustomerLedger({
  customerID,
  hideCustomerLedger,
}: CustomerTablesProps) {
  const showCustomerLedger = !hideCustomerLedger && !!customerID;
  const onRefresh = useRefresh();
  const { get } = useAngular();
  const zone = get(NgZone);
  const fq = get(FormsQuestionService);
  const data = get(DataService);
  const i18n = useI18n();
  const { paymentMode: payments } = usePaymentsModal();


  const onClickRecordPayment = useBind(fq.onClickRecievePayment, fq, customerID);
  const onClickProcessPayment = useCallback(() => { payments.emit("make-payment"); }, [payments]);

  const selectedRows = useRef<any[]>();

  return <>
    {showCustomerLedger && <>
      <Text as="p">To record shipping and other fees, first select a rental.</Text>
      <Card padding="0">
        <CardTitle title="Customer Ledger">
          <ButtonGroup>
            {data.status.isArlen && <ButtonAwait variant='tertiary' onClick={() => (async () => {
              console.log(selectedRows.current);
              if (!selectedRows.current) return;
              const paymentLines = selectedRows.current.filter(e => e.line.paymentLine).map(e => e.line.paymentLine.id);
              const invoiceLines = selectedRows.current.filter(e => e.line.invoiceLine).map(e => e.line.invoiceLine.id);
              console.log(paymentLines, invoiceLines);
              ok(paymentLines.length === 1);
              ok(paymentLines.length > 0);
              await data.server.serverCreateAutopayAttempts({ paymentLine: paymentLines[0], invoiceLines });
              onRefresh.emit({});
            })()}>Create Autopay Attempts</ButtonAwait>}
            {/* <ButtonAwait variant='tertiary' onClick={() => (async () => {
              ok(customerID);
              await data.server.serverSyncPaymentLines({ customerID });
              onRefresh?.emit({});
            })()}>Refresh</ButtonAwait> */}
            <Tooltip content="Record payment recieved by check or cash or run through your own credit card processor." dismissOnMouseOut>
              <Button onClick={onClickRecordPayment}>Record External Payment</Button>
            </Tooltip>
            <Tooltip content="Charge the customer's credit card or bank account held on file." dismissOnMouseOut>
              <Button variant="primary" tone="critical" onClick={onClickProcessPayment}>Charge Customer</Button>
            </Tooltip>
          </ButtonGroup>
        </CardTitle>
        <Divider />
        <CustomerLedgerTable
          key={customerID}
          customerID={customerID}
          rentalID=""
          onSelectRow={row => fq.onSelectLedgerRow(row, true)}
          selectionChange={rows => { selectedRows.current = rows; }}
        />
      </Card>
    </>}

  </>;

}

export function RentalTable({ customerID, balance }: { customerID: string; balance: string; }) {
  const { get, injector } = useAngular();
  const fq = get(FormsQuestionService);
  const data = get(DataService);
  const ui = get(UIService);
  const zone = get(NgZone);
  const table = "Customer";

  const rentalState = get(RentalListState);

  useForward(rentalState.onSaveSuccess, useRefresh());

  const [curSelected, setSelected] = useState<string>("");

  // rental view doesn't get a view change
  const view = useMemo(() => customerRentalView({ customerID }), [customerID]);

  const { rows, cols, idcol, loading, arraySort } = useTableListSimple<customerRentalViewResult>({ table: "Rental", view });

  const row = rows.find(e => idcol.get(e) === curSelected);

  const { buttonActions, extraActions }: {
    buttonActions: ActionButtonProps[];
    extraActions: { items: { content: string; onAction: () => void; }[]; }[];
  } = getRentalActions(row, rentalState);

  const { sortedRows, ...sortOpts } = useSortOpts(rows, cols, undefined, arraySort[0]);

  const headings = cols.filter(e => !e.hidden).map(e => ({
    title: e.title,
    alignment: "start",
    hidden: e.hidden,
    id: e.key,
  } satisfies IndexTableHeading)) as NonEmptyArray<IndexTableHeading>;

  const onClickScheduleUnit = useBind(rentalState.onClickScheduleUnit, rentalState, customerID, false);
  const onClickRentToOwn = useBind(rentalState.onClickScheduleUnit, rentalState, customerID, true);


  let position = 1;
  return (
    <Card padding="0" >
      <CardTitle title="Customer Rentals">
        <ButtonGroup>
          {FEATURE_FLAG_RENT_TO_OWN && <Button variant="secondary" onClick={onClickRentToOwn}>Rent To Own</Button>}
          <Button variant="primary" onClick={onClickScheduleUnit}>Schedule Rental</Button>
        </ButtonGroup>
      </CardTitle>
      <Divider />

      {loading ? <SpinnerBlock /> : <IndexTable
        resourceName={{ singular: "Rental", plural: "Rentals" }}
        itemCount={sortedRows.length}
        headings={headings}
        selectable={true}
        {...sortOpts}
        emptyState={null}
        onSelectionChange={(type, toggle, value) => {
          // ok(rentalState);
          // rentalState.selectedRental = toggle ? tree.table.find(e => tree.idcol.get(e) === value) : undefined;
          setSelected(toggle ? value as string : "");
        }}
      >{rentalState.customerRentalStages.map((stage, stageindex) =>
        sortedRows.filter(rentalState.stageFilter(stage)).map((row, index) => {
          const id = idcol.get(row);
          return (
            <IndexTable.Row
              id={id}
              key={id}
              selected={curSelected === id}
              position={position++}
              disabled={rentalState.disabled(row)}
            >
              {cols.map(col => (col.hidden ? null : <IndexTable.Cell key={col.key}>{valMarkup(col, row)}</IndexTable.Cell>))}
            </IndexTable.Row>
          );
        })
      )}
      </IndexTable>}
      <Modal
        open={!!curSelected}
        onClose={() => { setSelected(""); }}
        title={"Rental - Unit " + (row?.unit.Name ?? "")}
        size='large'
      >
        <Box padding="300">
          {/* <StartRental /> */}
          <CardTitle title="">
            <CustomActions buttonActions={buttonActions} extraActions={extraActions} reverse />
          </CardTitle>
          <Divider />
          {!!curSelected && <CustomerLedgerTable
            key={customerID + curSelected}
            rentalID={curSelected}
            customerID={customerID}
            onSelectRow={async row => { await fq.onSelectLedgerRow(row, true); }}

          />}
        </Box>
      </Modal>
    </Card>
  );

}
interface ActionButtonProps {
  content: string;
  onAction: () => void;
  variant?: "primary" | "plain" | "secondary" | "tertiary" | "monochromePlain" | undefined;
}


function getRentalActions(row: any, rentalState: RentalListState) {



  const buttonActions: ActionButtonProps[] = [];
  const extraActions: { items: { content: string; onAction: () => void; }[]; }[] = [];

  if (!row) return { buttonActions, extraActions };

  const status = rentalState.getNextStatus(row);
  if (status) {
    buttonActions.push({
      content: status.label,
      onAction: () => { rentalState.onClickNextStatus(row); },
      variant: "primary",
    });
  }

  buttonActions.push({
    content: "Edit Rental",
    onAction: () => { rentalState.onClickEditRental(row); }
  });

  buttonActions.push({
    content: "Photos",
    onAction: () => { rentalState.onClickEditPhotos(row); }
  });

  buttonActions.push({
    content: "Add Fees",
    onAction: async () => { rentalState.onClickExtraCharges(row); }
  });

  if (row && row.RentalStatus !== "Archived")
    extraActions.push(
      {
        items: [
          // { content: "Edit Rental", onAction: () => { zone.run(() => { rentalState.onClickEditRental(); }); } },
          // { content: "Add Charges", onAction: () => { zone.run(() => { rentalState.onClickExtraCharges(); }); } },
          // { content: "Manage Photos", onAction: () => { zone.run(() => { if (row.id) fq.onClickManageRentalPhotos(row.id); }); } },
          // { content: "Unit Rentals", onAction: () => { zone.run(() => { rentalState.onClickUnitRentals(); }); } },
          // { content: "Rental Ledger", onAction: () => { zone.run(() => { rentalState.onClickRentalLedger(); }); } },
          { content: "Go to Unit", onAction: () => { rentalState.navigate("Unit", row.unit.id); } },
        ]
      },
      {
        items: [
          { content: "Reverse Status", onAction: () => { rentalState.onClickReverseStatus(row); } },
        ]
      },
      {
        items: [
          { content: "Cancel Rental", onAction: () => { rentalState.onClickCancelRental(row); } },
        ]
      }
    );
  return { buttonActions, extraActions };
}

function CustomerLedgerTable({ customerID, rentalID, onSelectRow, selectionChange }: {
  customerID: string;
  rentalID?: string;
  onSelectRow: (row?: { line?: { invoiceLine?: { id: string; }; paymentLine?: { id: string; }; }; }) => void;
  selectionChange?: (rows: any[]) => void;
}) {
  const { get } = useAngular();

  const refresh = useObservable(useRefresh());

  const { result: customer } = usePrismaRequest(x => x.customer.findUnique({
    select: PrismaQuery.selectPathsProxy("Customer", x => [
      x.AutoPay.__,
    ] as const),
    where: { id: customerID }
  }), [customerID, refresh]);


  const state = useTableListInner({
    table: "CustomerLedger",
    views: useMemo(() => {
      const AND = !rentalID ? [{ customerID }] : [{ line: { invoiceLine: { rental: { id: rentalID } } } }]
      const views = ledgerTableViews<Prisma.CustomerLedgerWhereInput>(AND, false);
      views.forEach(e => e.sort = () => [])
      return views;
    }, [customerID, rentalID]),
    loadingMarkup: useLoadingMarkup("customer ledger lines"),
    resourceName: {
      singular: "Customer Ledger Line",
      plural: "Customer Ledger Lines",
    },
    onSelectRow: (id, row) => onSelectRow(row as any),
    selectMultiple: true,
    selectionChange: (selected) => {
      // console.log([...selected.keys()]);
      selectionChange?.(state.rows.filter(e => selected.has(state.idcol.get(e))));
    },
    defaultSort: false,
  });

  state.rows.sort((a, b) => {
    if (a.line.Date < b.line.Date) return -1;
    if (a.line.Date > b.line.Date) return 1;
    return +!a.line.paymentLine - +!b.line.paymentLine
  }).reverse();

  if (state.customs) state.customs.willAutopay.col.hidden = !customer?.AutoPay;

  return state.useMarkup();

}
// async singleDataQuery<T>(data: PrismaPromise<T>): Promise<T>;
function usePrismaRequest<T>(req: (x: PrismaClient) => PrismaPromise<T>, deps: React.DependencyList) {
  const { get } = useAngular();
  const data = get(DataService);
  return useAsyncEffect(async () => {
    return await data.singleDataQuery(req(data.proxy));
  }, undefined, undefined, deps);
}

function CustomerUnpaidTable({ customerID, state }: { customerID: string; state: TablePageCustomerAdapter; }) {
  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const data = get(DataService);

  const refresh = useObservable(useRefresh());

  // const { result: customer } = useAsyncEffect(async () => {
  //   return await data.singleDataQuery();
  // }, undefined, undefined, [customerID, refresh]);

  const { result: customer } = usePrismaRequest(x => x.customer.findUnique({
    select: PrismaQuery.selectPathsProxy("Customer", x => [x.AutoPay.__, x.PaymentInfoValid.__] as const),
    where: { id: customerID }
  }), [customerID, refresh]);

  const fees = useTableListInner({
    table: "CustomerLedger",
    views: useMemo(() => {
      return [{
        key: "valid",
        title: "All Valid",
        ...ledgerTableColumns,
        AND: [{
          customerID,
          line: {
            VoidSince: null,
            OR: [
              { invoiceLine: { paidOn: null, } },
              { paymentLine: { PaymentStatus: "Approved" } },
            ]
          }
        }] satisfies Prisma.CustomerLedgerWhereInput[]
      }]
    }, [customerID]),

    loadingMarkup: useLoadingMarkup("customer ledger lines"),
    resourceName: {
      singular: "Customer Ledger Line",
      plural: "Customer Ledger Lines",
    },
    onSelectRow: (id, row) => fq.onSelectLedgerRow(row as any, true),
    hiddenColumns: ["line/paymentLine/PaymentFee" as SPPI],

    hideFilters: true,
    emptyMarkup: (view) => <Banner title="No Unpaid Fees" tone="success" />
  });

  if (fees.customs) fees.customs.willAutopay.col.hidden = !customer?.AutoPay;

  const { manpayAmount, autopayAmount, pendingAmount } = getUnpaidFeesBalances(fees);

  const badgeAutopayProps = { size: "large", progress: "complete", tone: "success" } as const;
  const badgeManpayProps = { size: "large", progress: "incomplete", tone: "critical" } as const;

  const PaymentInfoValid = !!customer?.PaymentInfoValid;

  return <>
    <Card padding="0">
      <CardTitle title="Unpaid Fees and Pending Payments">
        <ButtonGroup>
          {customer && <ButtonAwait onClick={async () => {
            const enable = !customer.AutoPay;
            fq.messageConfirmation(
              enable ? "Enable Autopay" : "Disable Autopay",
              `Are you sure you want to ${enable ? "enable" : "disable"} autopay for this customer? 
              You should not change this without the customer's approval.`,
              async () => {
                await data.server.serverToggleCustomerAutopay({ customerID, autopay: !customer.AutoPay });
                emitGlobalRefresh();
              }
            );
          }}>{!customer.AutoPay ? "Enable Autopay" : "Disable Autopay"}</ButtonAwait>}
        </ButtonGroup>
      </CardTitle>
      <Box padding="300">
        <InlineStack gap="400">
          {/* {PaymentInfoValid && <Badge {...customer?.AutoPay ? badgeAutopayProps : badgeManpayProps}>
            {customer?.AutoPay ? "Autopay Enabled" : "Autopay Disabled"}
          </Badge>} */}
          {PaymentInfoValid && status(state, value => value?.AutoPay ? ["Autopay Enabled", "success", "success"] : ["Autopay Disabled", "error", "critical"])}
          {!PaymentInfoValid && status(state, state.customerStatusDisplay[2])}
          <Badge size="large" progress="incomplete" tone="critical">{`Unpaid Fees: ${manpayAmount}`}</Badge>
          {customer?.AutoPay ? <Badge size="large" progress="complete" tone="success">{`Autopay Scheduled: ${autopayAmount}`}</Badge> : null}
          <Badge size="large" progress="partiallyComplete" tone="warning">{`Pending Payments: ${pendingAmount}`}</Badge>
        </InlineStack>
      </Box>
      {fees.useMarkup()}
      <Box padding="300">
        <BlockStack gap="400">
          <p>
            Payment status <Badge progress="partiallyComplete" tone="warning">Approved</Badge> indicates the customer has made a payment but it hasn't cleared the bank yet.
            Until it has <Badge tone="success" progress="complete">Cleared</Badge> the bank, the invoice lines it applies to will still be in this list.
            Approved payments are included here for reference. See the Customer Ledger below for Cleared payments and Paid invoice lines.
          </p>
          <p>
            Autopay does not activate for a rental until the rental fee for the first month is paid.
            If the customer is paid up, this should not matter.
          </p>
        </BlockStack>
      </Box>
    </Card>
  </>

}

function getUnpaidFeesBalances(fees: ReturnType<typeof useTableListInner>) {
  ok(fees.customs);
  const amount = fees.colsMap.get("line/customerLedgerLine/Amount");
  const willAutoPay = fees.customs.willAutopay.col;
  const paymentstatus = fees.colsMap.get("line/paymentLine/PaymentStatus");
  ok(amount);
  ok(paymentstatus);
  const [invlines, paylines] = fees.rows.partition(e => paymentstatus.get(e));
  const [manpay, autopay] = invlines.partition(e => willAutoPay.get(e));
  const autopayAmount = Dinero({ amount: autopay.map(e => amount.get(e) ?? 0).reduce((a, b) => a + b, 0) }).toFormat("$0,0.00");
  const manpayAmount = Dinero({ amount: manpay.map(e => amount.get(e) ?? 0).reduce((a, b) => a + b, 0) }).toFormat("$0,0.00");
  const pendingAmount = Dinero({ amount: -paylines.map(e => amount.get(e) ?? 0).reduce((a, b) => a + b, 0) || 0 }).toFormat("$0,0.00");
  return { manpayAmount, autopayAmount, pendingAmount };
}

function MeasureWidth({ setCurrent }: { setCurrent: (width: number) => void }) {
  const ref = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<any>(null);
  useSyncExternalStore(useCallback((onStoreChange: () => void) => {
    const resizeObserver = new ResizeObserver((entries, observer) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => { onStoreChange(); }, 100);
    });
    if (ref.current) resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []), () => ref.current?.getBoundingClientRect().width);
  useLayoutEffect(() => { setCurrent(ref.current?.getBoundingClientRect().width ?? 0); });
  return <div ref={ref} style={{ alignSelf: "stretch" }} />;
}

function customerRentalView({ customerID, IS_TESTING }: { customerID?: string; IS_TESTING?: boolean; } = {}) {
  return ({
    key: "edit",
    title: "All",
    AND: [
      customerID ? { customer: { id: customerID } } : {},
      typeof IS_TESTING === "boolean" ? { customer: { IS_TESTING } } : {},
    ],
    list: customerRentalViewColumns as (e: any) => any,
    helptext: "",
    getCount: false,
  }) satisfies TableView<any[], "Rental", PrismaWhereQuery, symbol>;
}


const customerRentalViewColumns = (x: SelectTypeTree<"Rental">) => [
  x.StartDate.__,
  x.EndDate.__,
  x.RentalStatus.__,
  { key: x.unit.Name.__ },
  { key: x.unit.unitType.Name.__, title: "Unit Type" },
  { key: x.unit.currentBranch.DisplayName.__, title: "Branch" },
  { key: x.promotion.Title.__, title: "Promotion", hidden: true },
  { key: x.IsRentToOwn.__, hidden: true },
  { key: x.RentToOwnTotal.__, hidden: true },
  new TableViewColumn({
    key: x.IsRentToOwn.__,
    title: "Promotion",
    calculate: (row: {
      IsRentToOwn?: boolean | null;
      RentToOwnTotal?: number | null;
      promotion?: { Title?: string } | null;
    }) => {
      x.RentToOwnTotal.__;
      x.IsRentToOwn.__;
      x.promotion.Title.__;
      // parent?.child returns undefined if parent is null;
      if (row.IsRentToOwn === undefined
        || row.RentToOwnTotal === undefined
        || row.promotion === undefined
        || row.promotion && row.promotion.Title === undefined
      ) return null;
      return row;
    },
    markup: (val) => {
      if (!val) return null;
      const { IsRentToOwn, RentToOwnTotal } = val;
      if (!IsRentToOwn) return val.promotion?.Title;
      const text = typeof RentToOwnTotal !== "number" ? "" : DineroFactory({ amount: +RentToOwnTotal }).toFormat("$0,0.00");
      return (<Badge progress="complete" tone="info">{"Rent To Own: " + text}</Badge>);
    }
  }),

  { key: x.PriceOverride.__, title: "Price Override", hidden: true },
  { key: x.unit.unitType.RentalPrice.__, title: "Price", hidden: true },
  { key: x.unit.currentBranchMarkup.Markup.__, title: "Markup", hidden: true },
  {
    key: x.PriceOverride.__,
    title: "Rental Price",
    calculate: (row: {
      PriceOverride?: number | null;
      unit?: { unitType?: { RentalPrice?: number; }; currentBranchMarkup?: { Markup?: number | null } };
    }) => {
      //compile time error if the fields change in the schema
      x.PriceOverride.__;
      x.unit.unitType.RentalPrice.__;
      x.unit.currentBranchMarkup.Markup.__;
      //run time error if the fields change in the schema or aren't included in the request
      if (row.PriceOverride === undefined
        || row.unit?.unitType?.RentalPrice === undefined
        || row.unit?.currentBranchMarkup?.Markup === undefined
      ) return "|||";

      // return the total price
      if (row.PriceOverride)
        return row.PriceOverride;
      else
        return row.unit.unitType.RentalPrice + (row.unit.currentBranchMarkup.Markup ?? 0);
    },
    // markup: (val) => typeof val !== "number" ? null : DineroFactory({ amount: +(val as any) }).toFormat("$0,0.00"),
  },


  { key: x.unit.id.__, hidden: true },
  { key: x.promotion.id.__, hidden: true },
  { key: x.unit.currentBranch.id.__, hidden: true },
  { key: x.unit.currentOwner.id.__, hidden: true },
] as const satisfies ListValue[];

export type customerRentalViewResult = DataListResult<"Rental", [SPPI<["id"]>, ...ReturnType<typeof customerRentalViewColumns>]>;

function PipText({ children, tone, progress, accessibilityLabelOverride, ...rest }: PropsWithChildren<PipProps & Omit<TextProps, "as" | "tone">>): React.JSX.Element {
  return (
    <InlineStack gap="200" wrap={false}>
      <div>
        <Text as="span" variant="bodyLg" {...rest}>
          <span style={{ display: "inline-block", padding: ".0625rem" }}>
            <Pip as="div" {...{ tone, progress, accessibilityLabelOverride }} />
          </span>
        </Text>
      </div>
      <BlockStack gap="100">
        {children}
      </BlockStack>
    </InlineStack>
  );
}


// function Box3({ title, value, last }: { title: string, value: string, last?: boolean }) {
//   return <Box2 last={last}>
//     <BlockStack align='center' >
//       <Text as="span" >{title}</Text>
//       <Text as="span" fontWeight='semibold'>{value}</Text>
//     </BlockStack>
//   </Box2>
// }
// function Box4({ title, value, last }: { title: string, value: string, last?: boolean }) {
//   return <Box2 last={last}>
//     <Text as="span">{title}</Text>
//     <Text as="span" fontWeight='semibold'>{value}</Text>
//   </Box2>

// }

// interface Box2Props extends BoxProps {
//   last?: boolean
// }
// function Box2({ children, last, ...rest }: PropsWithChildren<Box2Props>) {
//   return <Box
//     // borderColor='border-secondary'
//     // borderStyle={last ? undefined : 'solid'}
//     // borderInlineEndWidth={last ? undefined : '025'}
//     // paddingBlockEnd={{ xs: "800" }}
//     // paddingBlockStart={{ xs: "800" }}
//     paddingInlineStart={{ xs: "300", md: "600" }}
//     paddingInlineEnd={{ xs: "300", md: "600" }}
//     {...rest}
//   >{children}</Box>
// }


function CustomActions({
  buttonActions,
  extraActions,
  reverse,
}: {
  buttonActions: ActionButtonProps[],
  extraActions: { items: { content: string, onAction: () => void }[] }[],
  reverse?: boolean;
}) {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const children = [
    ...buttonActions.map(({ content, onAction, variant }, index) =>
      <Button
        key={index}
        onClick={() => {
          setPopoverVisible(false);
          onAction();
        }}
        variant={variant}
      >{content}</Button>),
    <Popover
      active={popoverVisible}
      activator={<Button onClick={() => setPopoverVisible(true)}>...</Button>}
      preferredAlignment="right"
      onClose={() => { setPopoverVisible(false); }}
    >
      <ActionList sections={extraActions} />
    </Popover>
  ];

  if (reverse) children.reverse();

  return <ButtonGroup>{children}</ButtonGroup>;
}
