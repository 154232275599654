import { useLayoutEffect, useMemo } from "react";
// import { CircleAlertMajor, CircleTickMinor, EmailMajor } from "@shopify/polaris-icons";
// import { MessageService } from "primeng/api";
import { PropsWithChildren, useRef, useState, useSyncExternalStore } from "react";
import { useAngular, useAsyncEffect, useObservable } from "react-utils";

import { useDispatch } from "./useDispatch";
import { useSimpleValueFields } from "./useSimpleValueFields";
import { MessageService } from "react-utils";


/** Do not memoize this class. There are values which update on every render. */
export class useSimpleValueStore<T extends { readonly [K: string]: string }> extends useSimpleValueFields<T>{
  // public editing: boolean;
  public saving: boolean;
  public savedValue: T;
  // public action: (action: "edit" | "cancel" | "save" | "reset" | { key: keyof T; value: string; }) => void;
  action;
  editValue;
  saveValue;
  resetValue;
  cancelValue;
  constructor(
    initValue: T,
    saveValueCB: (value: T, valid: boolean) => Promise<void>,
    initEditing = false,
  ) {
    const [editing, setEdit] = useState(initEditing);

    super(initValue, editing);

    const { get } = useAngular();
    const ms = get(MessageService);
    const initRef = useRef(initValue);

    const [saving, setLoading] = useState(false);

    const valueRef = useRef<T>(initValue);
    const storeRef = useRef<() => void>(() => { });
    const savedValue = useSyncExternalStore<T>(cb => { storeRef.current = cb; return () => { }; }, () => valueRef.current);
    const [curValid, setValid] = useState(true);

    this.editValue = useDispatch(() => { setEdit(true); })

    this.saveValue = useDispatch(async () => {
      setLoading(true);
      await saveValueCB(this.curValue, this.valid);
      valueRef.current = this.curValue;
      setLoading(false);
      setEdit(false);
      ms.add({ severity: "success", summary: "Saved", detail: "Your changes have been saved" });
      storeRef.current();
    });

    this.cancelValue = useDispatch(() => {
      setEdit(false);
      this.setValue(valueRef.current);
    })

    this.resetValue = useDispatch(() => {
      initRef.current = { ...initValue };
      this.setValue({ ...initRef.current });
      this.setChangedFields("clear");
      this.setBlurredFields("clear");
    });

    this.action = useDispatch((action: "edit" | "cancel" | "save" | "reset" | { key: keyof T; value: string; }) => {
      if (action === "edit") { this.editValue(); }
      if (action === "cancel") { this.cancelValue(); }
      if (action === "save") { this.saveValue(); }
      if (action === "reset") { this.resetValue(); }
      if (typeof action === "object") {
        if (typeof action.key !== "string") throw new Error("key must be a string");
        this.setValue(curValue => ({ ...curValue, [action.key]: action.value }));
      }
    });

    const { valid } = this;

    useLayoutEffect(() => { setValid(valid); }, [valid]);

    const { curValue } = this;

    useLayoutEffect(() => { if (!editing) valueRef.current = curValue; }, [curValue, editing]);
  }


}