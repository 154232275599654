if (process.env.SERVERSIDE) { Error.stackTraceLimit = Infinity; } else { Error.stackTraceLimit = 30; }
import "reflect-metadata";
import "./graphql-declarator";
import "./cubes-schema";
import { TableField, TABLE_NAMES, TYPE_NAMES, FieldClass, ModelClass, ENUM_NAMES, EnumClass, Attributes, RootScals2, RootEnums2, RootTypes2, ViewListFunc } from "./cubes-utils";
import { SyncCheck, EnumType, RecordType, Wrapping, GraphRoot } from "./graphql-declarator";
import { CreateSQL, sqlPermissions } from "./cubes-prisma";
import { printCubes_fulltypes } from "./printCubes";
import { Customer, DivisionLedger } from "./cubes-schema";
import { createJoinQueryTest } from "./createJoinQuery";
export * from "./cubes-utils";

export { Group, GroupChild } from "./cubes-schema-helpers";

export { SyncCheck };

export type Root = typeof root;
export const root = new GraphRoot(new RootScals2, new RootEnums2, new RootTypes2, {});
root.build();


if (!root.registry.get(Customer)) throw "Registry isn't working right";

export const PermissionName = (action: "read" | "write", table: TABLE_NAMES, level: "table" | "groups" | "rows", remote: TABLE_NAMES) =>
  `${action} in ${table} for ${level} of ${remote}`;


const { tables, records } = Object.entries(
  root.types as Record<TYPE_NAMES, RecordType>
).reduce(
  ({ tables, records }, [name, target]) => (
    (target.__is("table") ? tables : records)[name as TYPE_NAMES] = new ModelClass(name, target),
    { tables, records }
  ),
  { tables: {}, records: {} } as Record<"tables" | "records", Record<TYPE_NAMES, TableField>>
);

const enums = Object.entries(root.enums).reduce(
  (n: any, [name, target]) => (
    (n[name] = {
      name: name,
      attributes: target.__typeAttributes(),
      options: (target as EnumType).__listMembers().reduce<any>(
        (n, [value, item], order) => {
          const attrs: Attributes = item.__fieldAttributes();
          return Object.assign(n, {
            [value]: {
              attributes: attrs,
              label: attrs.field?.first()?.title,
              icon: attrs.field?.first()?.lefticon,
              value,
              order,
            },
          })
        },
        {}
      ),
    }),
    n
  ),
  {} as DataSchema["enums"]
);



export class DataSchema {
  constructor(
    public tables: Record<string, ModelClass<TABLE_NAMES>>,
    public records: Record<string, ModelClass<Exclude<TYPE_NAMES, TABLE_NAMES>>>,
    public enums: Record<ENUM_NAMES, EnumClass>,
  ) { }

  anyModel(type: TYPE_NAMES) {
    let item = this.tables[type] || this.records[type];
    if (!item) { debugger; throw new Error(); }
    return item;
  }
  typepath(name: string, path: string): ModelClass<any>[] {
    let target = this.tables[name] || this.records[name];
    return path.split("/").reduce((n, f, j, p) => {
      let last = n.last();
      if (!last) throw new Error("last not found");
      if (last instanceof Wrapping) last = last.__wrap;

      let field = last?.fields[f];

      if (!field) {
        debugger;
        throw new Error(`List item ${path} tried to descend into undefined ${f} after index ${j}`);
      } else if (field.isType("scalar")) {
        if (j < p.length - 1) throw (`List item ${path} tried to descend into Scalar type ${field.name} after index ${j}`);
      } else if (field.isType("record") || field.isType("table")) {
        n.push(this.tables[field.name] || this.records[field.name]);
      } else if (field.isType("enum")) {
        if (j < p.length - 1) throw (`List item ${path} tried to descend into Enum type ${field.name} after index ${j}`);
      } else {
        debugger;
        throw new Error(`List item ${path} tried to descend into unknown type ${field.name} after index ${j}`)
      }
      return n;
    }, [target] as ModelClass<any>[]);
  }
  typepaths(name: string, list: string[]): ModelClass<any>[][] {
    return list.map(e => this.typepath(name, e));
  }
  getpath(name: string, path: string): FieldClass {
    const key = path.split("/").last();
    const item = this.typepath(name, path).last();
    if (!key || !item) throw new Error("invalid path " + path);
    return item.fields[key];
  }
}


export const schema: DataSchema = new DataSchema(
  tables as any,
  records as any,
  enums,
);
declare global {
  namespace NodeJS {
    interface ProcessEnv {
      SERVERSIDE: string;
    }
  }
}

if (process.env.SERVERSIDE) {



  const { writeFileSync } = require("fs") as typeof import("fs");
  const { join } = require("path");
  console.log("writing files");

  const schemas = [
    { path: "/home/cubes/server/datamath/prisma", output: false, kysely: false },
    { path: "/home/cubes/server/datamath", output: "./node_modules/prisma-client", kysely: "./kysely/" },
    { path: "/home/cubes/server/do-app", output: "./client", kysely: false },
  ];

  schemas.forEach(({ path, output, kysely }) => {
    // writeFileSync(path + "/dataschema.json", JSON.stringify(schema, null, 2));
    writeFileSync(path + "/schema.prisma", CreateSQL(root, output, kysely));
    writeFileSync(path + "/privelages.sql", sqlPermissions(root, "cubeswebdev2"));
  });

  writeFileSync("/home/cubes/server/datamath/schema-builder/printCubes-attributes.ts", printCubes_fulltypes());
  
  writeFileSync("/home/cubes/server/datamath/prisma/privelages.sql", sqlPermissions(root, "cubeswebdev2"));
  writeFileSync("/home/cubes/server/datamath/prisma-live/privelages-update.sql", sqlPermissions(root, "cubeswebapp3"));

  writeFileSync("/home/cubes/server/datamath/prisma-schemas.sh", [
    "set -x # print each command as it is executed",
    "set -e # exit if any line ends with a non-zero exit status",
    "set -u # exit script if a variable is uninitialized",
    schemas.map(e => `(cd ${e.path} && npx prisma format${e.output ? " && npx prisma generate" : ""})\n`).join("")
  ].join("\n"))


} else {
  console.log("NOT SERVERSIDE");

  createJoinQueryTest();
}

