import { EventEmitter } from "@angular/core";
import { ServerStatus, is, } from "common";
import { DataService } from "data-service";
import React, { useCallback, useEffect, useRef } from "react";
import { useAsyncEffect, useAsyncEffectBase, useAngular, useObserver, } from "react-utils"
import { BranchSelectorRefresh } from "../utils";
import { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "@shopify/polaris";
import { FormsQuestionService } from '../utils';
import { useRefresh } from "react-utils";
import { AuthService } from "../utils/auth.service";

export function LoginHost() {
  const { get } = useAngular();
  const auth = get(AuthService);
  const data = get(DataService);
  const fq = get(FormsQuestionService);
  const [showLogin, setShowLogin] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [timestamp, setTimestamp] = useState(new Date().toLocaleTimeString());
  const [showBranches, setShowBranches] = useState("");
  useObserver(useRefresh(), () => {
    setShowBranches("");
    setTimestamp(new Date().toLocaleTimeString());
  });
  useEffect(() => {
    if (showBranches)
      fq.onClickEditUser(showBranches);
  }, [fq, showBranches]);
  useEffect(() => {
    if (cancelled) return;
    data.login().then(() => {
      setShowLogin(false);
      BranchSelectorRefresh.emit({});
    }).catch(async e => {
      if (is<ServerStatus>(e, e[DataService.NO_BRANCHES])) {
        setShowBranches(e.userID);
      } else {
        console.log(e, timestamp);
        setStatusText(e.toString());
        setShowLogin(true);
        await new Promise(r => setTimeout(r, 5000));
        setTimestamp(new Date().toLocaleTimeString());
      }
    });
  }, [data, cancelled, timestamp, fq]);

  // console.log("LoginHost", showLogin, statusText, timestamp, cancelled);

  return <Modal
    key="login"
    open={showLogin}
    onClose={() => { setShowLogin(false); setCancelled(true); }}
    title={"Logging in... " + timestamp}
  >
    <Modal.Section>
      <p>Email: {auth.email}</p>
      <p>{statusText}</p>
    </Modal.Section>
  </Modal>;

}

export function useLogin() {
  const { get } = useAngular();
  const data = get(DataService);
  const auth = get(AuthService);
  const [showLogin, setShowLogin] = useState(false);
  const cancelled = useRef(false);
  const [statusText, setStatusText] = useState("");
  const [timestamp, setTimestamp] = useState("");

  const login = useCallback(async () => {

    if (cancelled) return;

    await data.login().catch(async e => {
      console.log(e);
      setStatusText(e);
      setShowLogin(true);
      setTimestamp(new Date().toLocaleTimeString());
      await new Promise(r => setTimeout(r, 5000));
      return login();
    });

    setShowLogin(false);
    return true;
  }, [data]);

  const { result, error, loading } = useAsyncEffect(login);

  return { loading, ready: result, showLogin };
}