import React from "react";
import { BlockStack, Box, Button, ButtonGroup, ChoiceList, ChoiceListProps, InlineError, InlineGrid, InlineStack, Text } from "@shopify/polaris";
// import { CircleAlertMajor, CircleTickMinor, EmailMajor } from "@shopify/polaris-icons";
import { PropsWithChildren } from "react";
import { card, } from 'creditcards';
import { CardType, defaults as cardTypes } from 'creditcards/types';
import type { useSimpleValueStore } from "./useSimpleValueStore";

export const cardLookup = cardTypes.reduce((n, e) => (n[e.name] = e, n), {} as Record<string, CardType>);
export function formatUserInput(number: string) {
  number = number.split(" ").join("");
  const type = card.type(number, true);
  return !type ? number : cardLookup[type].group(number).join(" ");
}

/**
 * 
 * @param setValue
 * @param saving 
 * @returns 
 */
export const saveButtons = (store: useSimpleValueStore<any>) => {
  return <InlineStack align="end">
    {!store.valid ? <InlineError message="Some fields are not correctly filled" fieldID="error" /> : null}
    <ButtonGroup>
      <Button variant="tertiary" onClick={() => { store.action("cancel"); }}>Cancel</Button>
      <Button variant="primary" disabled={!store.valid} onClick={() => { store.valid && !store.saving && store.action("save"); }} loading={store.saving}>Save</Button>
    </ButtonGroup>
  </InlineStack>;
};

export const titlebody = (title: string, body: any) => (
  <BlockStack gap="100">
    <Text as="h4" variant="headingMd">{title}</Text>
    <Text as="span" variant="bodyMd">{body}</Text>
  </BlockStack>
);
export const titlelink = (title: string, link: string, onClick: () => void) => (
  <BlockStack gap="100">
    <Text as="h4" variant="headingMd">{title}</Text>
    <div><Button onClick={onClick}>{link}</Button></div>
  </BlockStack>);



export function SimpleValueStoreSection<T extends Record<string, string>>({ store, title, children }: PropsWithChildren<{ title: string, store: useSimpleValueStore<T> }>) {
  return <SettingsSection
    title={title}
    action={store.editing ? undefined : <Button onClick={() => store.action("edit")}>Edit</Button>}
  >
    {children}
    {store.editing ? saveButtons(store) : null}
  </SettingsSection>;
}


export function SettingsSection({ title, helptext, action, children }: PropsWithChildren<{ title: string; helptext?: string; action?: React.JSX.Element; }>) {
  return <InlineGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="400">
    <Box
      as="section"
      paddingInlineStart={{ xs: "400", sm: "0" }}
      paddingInlineEnd={{ xs: "400", sm: "0" }}
    >
      <BlockStack gap="400">
        <InlineStack align="space-between" blockAlign="center">
          <Text as="h3" variant="headingMd">
            {title}
          </Text>
          {action ?? null}
        </InlineStack>

        {(!helptext ? null : (
          <Text as="p" variant="bodyMd">
            {helptext}
          </Text>
        ))}
      </BlockStack>
    </Box>
    {/* <Card roundedAbove="sm"> */}
    <BlockStack gap="400">
      {children}
    </BlockStack>
    {/* </Card> */}
  </InlineGrid>;
}
SettingsSection.Wrapper = ({ children }: PropsWithChildren<{}>) => <BlockStack gap={{ xs: "800", sm: "400" }}>{children}</BlockStack>;


export function ChoiceListInlineStack({ title, choices, selected, onChange, error }: {
  title?: string;
  error?: string;
  choices: ChoiceListProps["choices"];
  selected: string[];
  onChange: (value: string[]) => void;
}) {
  return (
    <BlockStack gap="100">
      {title ? <Text as="h4" variant="bodyMd">{title}</Text> : null}
      <InlineStack align="start" gap="300">
        {choices.map(e => (
          <ChoiceList
            title={title}
            titleHidden
            choices={[e]}
            selected={selected}
            onChange={onChange}
          />
        ))}
      </InlineStack>
      <InlineError message={error ?? ""} fieldID="error" />
    </BlockStack>
  );

}

