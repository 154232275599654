import { Card, Page } from "@shopify/polaris";
import { TABLE_NAMES } from "common";
import { DataService } from "data-service";
import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ButtonAwait, useAngular, useAsyncEffect, useRoute } from "react-utils";

export function StripeReturnPage() {
  const { table, view2: view, id } = useRoute();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    navigate(`/${table}/edit/${id}`);
  }, [navigate, table, id]);
  return null;
}


export function StripeRefreshPage() {
  const { table, view2: view, id } = useRoute();

  return <Page
    title={`Stripe Link Expired`}
  >
    <Card>
      <p>Stripe thinks a link expired. Please go to the {table} you wanted to update payout info for and try again.</p>
    </Card>
  </Page>
}
