/* eslint-disable no-restricted-globals */
import React, { useCallback, useImperativeHandle } from "react";
import { BlockStack, ButtonGroup, InlineError, InlineStack, Modal, Text } from "@shopify/polaris";
import { useRef, useState } from "react";
import { ButtonAwait, useAngular, useAsyncEffect, useEventEmitter, useObservable, useObserver, useSubscribe } from "react-utils";
import { useSimpleValueStore } from "./useSimpleValueStore";
import { ChoiceListInlineStack, formatUserInput, titlebody } from "./fields";
import { CreditCardParser } from "./CreditCardParser";
import { PaymentApp, PaymentModelProps, useFormUrl, usePaymentStatus } from "./usePaymentStatus";
import { EventEmitter } from "@angular/core";
import { PaymentLedgerKeys } from "common";
import { DataService } from "data-service";
import { PaymentMode } from "./ProvidePaymentProcessor";
import { DeleteIcon } from "@shopify/polaris-icons";
import { FormsQuestionService } from "../utils/forms-question.service";

export type contactkeys = "Name" | "Address" | "Phone number";
export type contactinfo = Partial<Record<contactkeys, string>>;

export type cardkeys = "Card Number" | "Expiry Date";
export type cardinfo = Partial<Record<cardkeys, string>>;

const formatExpiry = (value: string) => {
  value = value.split("/").join("");
  return value.slice(0, 2) + (value.length > 2 ? "/" : "") + value.slice(2);
}

const accountChoices = [{ label: "Checking", value: "checking" }, { label: "Savings", value: "savings" }];
const entityChoices = [{ label: "Personal", value: "personal" }, { label: "Business", value: "business" }];


export function PaymentInfoModel({ events, request, onSaveEvent, ...app }: {
  PaymentLedger: PaymentLedgerKeys,
  events: EventEmitter<PaymentMode>,
  request: PaymentApp["request"],
  updateStatus: PaymentApp["updateStatus"],
  onSaveEvent: PaymentApp["onSaveEvent"],
  formUrl: PaymentApp["formUrl"],
  status: PaymentApp["status"],
}) {

  const { get } = useAngular();
  const data = get(DataService);
  const fq = get(FormsQuestionService);
  if (!data.status) throw data.ready;
  console.log(location.href);
  const url = new URL(location.href);
  const [paymentFormTokenId, setPaymentFormTokenId] = useState(url.searchParams.get("token-id"));
  if (url.searchParams.has("token-id")) { url.searchParams.delete("token-id"); history.replaceState(null, "", url.href); }

  // this only happens on page load so we don't need anything to refresh it
  // it also only affects updating payment info, not making payments, so we don't need to refresh the page
  const { loading: loadingToken, result: tokenResult } = useAsyncEffect(async () => {
    if (!paymentFormTokenId) return;
    let { success, reason, } = await request("token-id", { tokenId: paymentFormTokenId });
    success = success ?? false;
    reason = reason ?? "";
    if (success) { events.emit("update-payment-success"); }
    return { success, reason };
  });

  const [open, setOpen] = useState(!!paymentFormTokenId);

  useObserver(events, e => {
    switch (e) {
      case "update-payment": setOpen(true); break;
      case "": setOpen(false);
    }
  });

  const onClose = () => {
    setPaymentFormTokenId(null);
    setOpen(false);
    app.updateStatus();
  }


  return !paymentFormTokenId ? (
    <Modal
      open={open}
      onClose={() => { setOpen(false) }}
      title="Update Payment Method"
      secondaryActions={[{ content: 'Close', onAction: onClose }]}
      primaryAction={{ content: 'Ok', onAction: () => { onSaveEvent.emit(); } }}
    >
      <Modal.Section>
        <PageBilling {...{ ...app, onSaveEvent }} />
      </Modal.Section>
    </Modal>
  ) : (
    <Modal
      open={open}
      onClose={() => { }}
      title="Update Payment Method"
      primaryAction={{ content: 'Ok', loading: loadingToken, onAction: () => { if (tokenResult) { onClose(); } }, }}
    >
      <Modal.Section>
        <p>{
          loadingToken ? "Please wait while we process the information." :
            tokenResult?.success ? "Thank you. The payment information has been saved!" :
              "Error: " + tokenResult?.reason
        }</p>
      </Modal.Section>
    </Modal>
  );
}


export const PageBilling = ({ formUrl: formUrl$, onSaveEvent, status, PaymentLedger }: {
  // Pick<PaymentApp, "formUrl" | "onSaveEvent" | "status">
  formUrl: PaymentApp["formUrl"],
  onSaveEvent: PaymentApp["onSaveEvent"],
  status: PaymentApp["status"],
  PaymentLedger: PaymentLedgerKeys,
}) => {

  const cardform = useRef<HTMLFormElement | null>(null);

  const card = new useSimpleValueStore({
    "billing-cc-number": "",
    "billing-cc-exp": "",
    "billing-cc-cvv": "",
    "billing-account-name": "",
    "billing-routing-number": "",
    "billing-account-number": "",
    "billing-account-type": "",
    "billing-entity-type": "",
  }, async (value, valid) => {
    if (valid) cardform.current?.submit();
    // this causes the page to navigate, so we just return a never ending promise
    return new Promise(() => { });
  }, true);

  const { action } = card;

  useObserver(onSaveEvent, useCallback(() => { action("save"); }, [action]));

  const formUrl = useObservable(formUrl$, null);

  const [method, setMethod] = useState([PaymentLedger === "Customer" ? "cc" : "ck"]);


  const type = status.PaymentInfoFlags?.[0] as "c" | "k" | "n";

  const isCard = type === "c";
  const isBank = type === "k";

  return <>
    {formUrl && <form
      ref={cardform}
      action={formUrl}
      method="post"
    >{Object.entries(card.curValue).map(e => (
      <input
        type="hidden"
        name={e[0]}
        value={e[0] === "billing-routing-number" ? e[1].split(" ").join("") : e[1]}
      />
    ))}
    </form>}
    <BlockStack gap="600">
      {!card.editing ? [
        isCard ? titlebody("Card Number", status.ccNumber) : null,
        isCard ? titlebody("Expiry Date", status.ccExpiry) : null,
        isBank ? titlebody("Routing Number", status.ckRouting) : null,
        isBank ? titlebody("Account Number", status.ckAccount) : null,
      ] : <>
        {card.editing && PaymentLedger === "Customer" ? <ChoiceListInlineStack
          choices={[{ label: 'Credit Card', value: 'cc' }, { label: 'Bank Transfer', value: 'ck' },]}
          selected={method}
          onChange={(value) => {
            setMethod(value);
            card.action("reset");
          }}
        /> : null}

        {method[0] === "cc" ? card.TextField({
          key: "billing-cc-number", title: "Card Number", onChangeMap: (value) => formatUserInput(value), required: true,
          validate: ({ "billing-cc-number": value }) => {
            return (!CreditCardParser.checkCardNumber(value.split(" ").join(""))) ? "Invalid card number" : "";
          }
        }) : null}
        <InlineStack align="start" gap="300">
          {method[0] === "cc" ? card.TextField({
            key: "billing-cc-exp", title: "Expiry Date", onChangeMap: formatExpiry, required: true,
            validate: ({ "billing-cc-exp": value }) => {
              return (!CreditCardParser.checkExpiration(value)) ? "Invalid expiration date" : "";
            }
          }) : null}
          {method[0] === "cc" ? card.TextField({
            key: "billing-cc-cvv", title: "CVV", required: true,
            validate: ({ "billing-cc-cvv": cvv, "billing-cc-number": ccn }) => {
              return (!CreditCardParser.checkCVV(ccn.split(" ").join(""), cvv)) ? "Invalid code" : "";
            }
          }) : null}
        </InlineStack>

        {method[0] === "ck" ? card.TextField({
          key: "billing-account-name",
          title: "Name on Check",
          required: true,
        }) : null}

        <InlineStack align="start" gap="800">
          {method[0] === "ck" ? card.TextField({
            key: "billing-routing-number",
            title: "Routing Number",
            onChangeMap: (value) => formatSpacing(value, 3),
            required: true
          }) : null}
          {method[0] === "ck" ? card.TextField({
            key: "billing-account-number",
            title: "Account Number",
            required: true
          }) : null}
        </InlineStack>

        <InlineStack align="start" gap="800">
          {method[0] === "ck" ? card.ChoiceListInline({ key: "billing-account-type", title: "Account Type", choices: accountChoices, required: true }) : null}
          {method[0] === "ck" ? card.ChoiceListInline({ key: "billing-entity-type", title: "Entity Type", choices: entityChoices, required: true }) : null}
        </InlineStack>

      </>}
    </BlockStack>
  </>
}
function formatSpacing(input: string, spacing: number) {
  return input.split(" ").join("").replace(new RegExp(`(.{${spacing}})`, "g"), "$1 ").trim();
}
