import { Injectable, Injector } from "@angular/core";
import { ConfirmType, DataQueryGraph, MemberKeys, root, schema, TABLE_NAMES, InvoiceLine, ProxyPromise, proxy, Roles, ok } from "common";
import { SRData, SRKeys, SRResult, PrismaQuery, ServerStatus, CRKeys, CRData, CRResult, SyncCheck, SPPI } from "common/datamath";
import { AuthService } from "./auth.service";
import dinero from "dinero.js";
import { ChartConfiguration } from "chart.js";
import * as PrismaExtra from "prisma-client";
import { PrismaPromise, Prisma, PrismaClient } from "prisma-client";
import { SRMap } from "common/datamath";
import { PromiseSubject, ReactInjectable } from "react-utils";

export const ActionModeMap: Record<Exclude<MemberKeys<ConfirmType>, "READ">, "Create" | "Update" | "Delete"> = {
  CREATE: "Create",
  UPDATE: "Update",
  DELETE: "Delete",
}
type Mode = MemberKeys<ConfirmType>;
/** Returns undefined if parsing fails since undefined cannot be parsed from JSON */
export function tryParseJSON(arg: string) {
  try {
    return JSON.parse(arg)
  } catch (e) {
    return undefined;
  }
}


interface IPrismaReq {
  table: string;
  action: string;
  arg: any;
}

interface ClientStatus extends ServerStatus {
  branchID: string,
  branchType: PrismaExtra.BranchType,
  branchName: string,
  divisionID: string,
  divisionName: string,
}

@ReactInjectable()
@Injectable({ providedIn: 'root' })
export class DataService {

  static readonly NO_BRANCHES: unique symbol = Symbol("no branches");
  static proxy = proxy as PrismaClient;
  ready = new PromiseSubject<boolean>();
  loggedin: boolean | null = null;
  currentBranchID: string;
  userRole: Roles;
  status: ClientStatus;
  private auth;
  constructor(injector: Injector) {
    this.auth = injector.get(AuthService);
    (window as any).prismatransact = (opts: any) => this.singleDataQuery(opts);
    (window as any).prismaexport = async () => {
      let data = await this.getServerRequest(
        "requests",
        Object.keys(schema.tables as any)
          .map(e => new ProxyPromise({ table: e as any, action: "findMany", arg: {} }))
      );
      return Object.keys(schema.tables as any).reduce(
        (n, e, i) =>
          (n[e] = data[i].statusCode === 200 ? data[i].body : null, n),
        {} as any
      );
    };
  }
  branchInfo: {
    branchID: string;
    markup: Record<string, number>;
  }

  selectPaths(table: TABLE_NAMES, paths: readonly SPPI[], forInclude: boolean) {
    return PrismaQuery.getQueryTree(["id" as any, ...paths], root.types[table], forInclude);
  }
  queryFormData(table: TABLE_NAMES, id: string, group: { findGetPaths: (prefix: string, userRole: Roles) => string[] }) {
    return {
      action: "findUnique",
      table,
      arg: {
        select: this.selectPaths(table, ["id", ...group.findGetPaths("", this.userRole)] as SPPI[], false),
        where: { id: id },
      }
    } as any;
  }


  async login() {
    // await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    // await Auth.federatedSignIn()

    const loggedin = await this.auth.isLoggedIn();
    this.loggedin = loggedin;
    if (!loggedin) return;

    const status: ServerStatus = await this.getServerRequest("status", {});
    if (!status) return;

    if (!status.Branches.length) {
      if (status.isAdmin) throw { [DataService.NO_BRANCHES]: true, ...status };
      else throw new Error("no branches");
    }

    const central = status.Branches.find(e => e.branch.BranchType === "CENTRAL");
    const dealer = status.Branches.find(e => e.branch.BranchType === "DEALER");
    const best = central ?? dealer ?? status.Branches[0];

    this.status = {
      ...status,
      branchID: best.branch?.id,
      branchType: best.branch?.BranchType,
      branchName: best.branch?.DisplayName,
      divisionID: best.branch?.division?.id,
      divisionName: best.branch?.division?.Name,
    }

    this.currentBranchID = this.status.branchID ?? "";
    this.userRole = this.status.isAdmin ? "web_admin" : "web_user";

    if (!this.status.isProd) window.document.body.classList.add("is-dev-site");

    console.log(this.status);
    this.ready.resolve(true);
  }



  prisma: PrismaClient = ((self) => new Proxy<any>({}, {
    get(target: any, table: string, receiver) {
      return new Proxy<any>({}, {
        get(target: any, action: any, receiver) {
          return (arg: any) => self.singleDataQuery(new ProxyPromise({
            action,
            table: capitalize(table) as TABLE_NAMES,
            arg
          }))
        }
      })
    }
  }))(this);

  async singleDataQuery<T>(data: PrismaPromise<T>): Promise<T>;
  async singleDataQuery(data: Omit<ProxyPromise, "#error">): Promise<any>;
  async singleDataQuery(data: Omit<ProxyPromise, "#error"> | PrismaPromise<any>): Promise<any> {
    data = data as ProxyPromise;
    return await this.dataGraphQuery(new DataQueryGraph(data.table, undefined, this.userRole), "transact", data);
  }

  async dataGraphQuery<T>(data: DataQueryGraph, type: "requests" | "transact", caller: PrismaPromise<T>): Promise<T>;
  async dataGraphQuery(data: DataQueryGraph, type: "requests" | "transact", caller: Omit<ProxyPromise, "#error">): Promise<any>;
  async dataGraphQuery(data: DataQueryGraph, type: "requests" | "transact"): Promise<void>;
  async dataGraphQuery(data: DataQueryGraph, type: "requests" | "transact", caller?: Omit<ProxyPromise, "#error"> | PrismaPromise<any>) {
    let callerstack = new Error("caller stack");
    let prom = caller ? data.addPromise(caller as ProxyPromise) : Promise.resolve();

    // extras get called and awaited, but we aren't concerned about the result
    const [result] = await Promise.all([
      this.getServerRequest(type, data.getRequests()), ...data.extraRequests.map(e => e())
    ]);

    data.hookBeforeResponse.forEach(e => e());

    if (type === "requests")
      data.actions.forEach((e, i) => {
        let { body, statusCode } = result[i];
        if (statusCode !== 200) {
          console.log(type, statusCode, e.req, body, ProxyPromise.getErrorStack(e.req), callerstack.stack);
          throw new Error("unexpected response " + statusCode);
        } else {
          if (e.res) e.res(body);
        }
      });
    else if (type === "transact")
      // transact won't resolve if there are errors, so no need to handle that here
      data.actions.forEach((e, i) => {
        if (e.res) e.res(result[i]);
      });

    data.hookAfterResponse.forEach(e => e());

    return prom;

  }

  CustomerUnpaidTxnsTable = [
    "line/Date", "line/customerLedgerLine/Amount", "rental/unit/Name", "line/Description",
  ] as const satisfies readonly (MemberKeys<InvoiceLine> | `${MemberKeys<InvoiceLine>}/${string}`)[];

  server: SRMap = new Proxy({}, {
    get: (target, prop) => (data: any, skipAlert?: boolean) => this.getServerRequest(prop as any, data, skipAlert)
  }) as any;
  /** This will throw if the server does not return a successful result */
  server3<K extends CRKeys>(name: K): (data: CRData<K>) => CRResult<K>;
  server3<K extends SRKeys>(name: K): (data: SRData<K>) => SRResult<K>;
  server3(name: any): (data: any) => Promise<any> {
    const sync = new SyncCheck();
    return async (data) => (sync.done(), await this.getServerRequest(name, data));
  }

  async getServerStatus(): Promise<ServerStatus> {
    return await this.getServerRequest("status", {});
  }

  async getServerRequest<D>(
    name: D extends DataQueryGraph
      ? "use dataGraphQuery instead of getServerRequest"
      : ("status" | "requests" | "transact" | CRKeys | SRKeys),
    data: D, skipAlert?: boolean
  ): Promise<any> {
    const endpoint = localStorage.getItem("cubes-dev-endpoint") ?? "api";
    if (data instanceof DataQueryGraph) throw new Error("use dataGraphQuery instead of getServerRequest");
    const res = await fetch(`/${endpoint}/${name}`, {
      method: "PUT",
      headers: {
        ...await this.auth.getHeaders(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      cache: "no-store",
    });

    const body = await res.text();
    const json = tryParseJSON(body);

    if (res.status !== 200) {
      if (name === "requests" || name === "transact" || name === "status") {
        throw json ?? body;
      } else if (json === undefined && body !== "CLIENT_COMPLETED_INVOICE_LINES") {
        if (!skipAlert) alert(`ERROR - ${name}:\n${body}`);
        throw json ?? body;
      }
    }

    if ((name === "requests" || name === "transact") && !Array.isArray(json)) {
      throw new Error("the endpoint returned a non-array response with status 200");
    } else {
      return json;
    }




    // firstValueFrom(of(null).pipe(
    //   switchMap(async () => this.http.put(`/api/${name}`, data, {
    //     headers: await this.auth.getHeaders(),
    //     responseType: "json",
    //     observe: "response",
    //   })),
    //   switchAll(), // Promise resolves to Observable
    //   catchError((res: HttpErrorResponse) => {
    //     console.log("error", name, res.error, res.message);
    //     if (name === "requests" || name === "transact") {
    //       throw res.error;
    //     } else if (res.error === "CLIENT_COMPLETED_INVOICE_LINES") {
    //       throw res.error;
    //     } else if (name === "status") {
    //       throw res.error;
    //     } else if (typeof res.error === "string") {
    //       alert(`ERROR - ${name}:\n${res.error}`);
    //       throw "";
    //     } else {
    //       throw res.error;
    //     }
    //   }),
    //   map(res => {
    //     if ((name === "requests" || name === "transact") && !Array.isArray(res.body)) {
    //       throw new Error("the endpoint returned a non-array response with status 200");
    //     } else {
    //       return res.body;
    //     }
    //   }),
    // ));
  }



  chartColors = {
    'Red': (t: number) => `rgba(255, 99, 132, ${t})`,
    'Blue': (t: number) => `rgba(54, 162, 235, ${t})`,
    'Yellow': (t: number) => `rgba(255, 206, 86, ${t})`,
    'Green': (t: number) => `rgba(75, 192, 192, ${t})`,
    'Purple': (t: number) => `rgba(153, 102, 255, ${t})`,
    'Orange': (t: number) => `rgba(255, 159, 64, ${t})`,
    'CubesYellow': (t: number) => `rgba(254, 152, 0, ${t})`,
  } satisfies Record<string, (t: number) => string>;

  getDaysPastDueChart(res: { Period: number; Amount: string; }[]) {

    res = res.filter(e => e.Period > 0).sort((a, b) => b.Period - a.Period);
    // const max = res.reduce((n, e) => Math.max(n, +e.Amount), 0);
    const max = res.length;
    return {
      type: "bar",
      data: {

        labels: res.map(e => e.Period),
        datasets: [
          {
            data: res.map(e => dinero({ amount: +e.Amount }).toUnit()),
            label: "",
            backgroundColor: this.chartColors.CubesYellow(0.6),
            borderColor: this.chartColors.CubesYellow(1),
          }
        ],

      },
      options: {
        maintainAspectRatio: false,

      },
      plugins: []
    } satisfies ChartConfiguration<"bar">
  }


  calcCustomerBalance(customer: PrismaExtra.Customer & {
    AllRentals: (PrismaExtra.Rental & {
      InvoiceLines: (PrismaExtra.InvoiceLine & {
        item: PrismaExtra.Item | null;
      })[];
    })[];
  }) {
    let balance = dinero({ amount: 0 });

    return balance;
  }


  async openStripeWindow(table: string, id: string) {
    ok(table === "Branch" || table === "Owner" || table === "Division");

    if (!this.status.isArlen) {
      if (table === "Branch" && !this.status.Branches.find(e => e.branch.id === id)
        || table === "Owner" && !this.status.Owners.find(e => e.owner.id === id))
        return alert("The payout info can only be updated by the person recieving the payouts. "
          + "This button can only be used by someone assigned to this " + table + ".");
      if (table === "Division")
        return alert("Division payouts aren't setup for users yet. Talk to Arlen about setting this up.");
    }

    // leave this here for now, but it shouldn't be necessary. 
    await this.server.serverStripeAccountGetStatus({ PaymentLedger: table, hostID: id });
    const link = await this.server.serverStripeAccountLink({ PaymentLedger: table, hostID: id });
    if (link) location.href = link.url;
  }


  proxy = proxy as PrismaClient;


}
function capitalize<T extends string>(table: T): Capitalize<T> {
  return table.slice(0, 1).toUpperCase() + table.slice(1) as any;
}
