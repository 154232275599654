import { BlockStack, Card, Page, Text } from "@shopify/polaris";
import { DataQueryGraph, ok, proxy, SPPI, WHERE_balanceWhereLine } from "common";
import * as PrismaExtra from "prisma-client";
import { DataService } from "data-service";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { useAngular, useAsyncEffect, useObservable, useRefresh } from "react-utils";
import { QuestionTableComp } from "../components/QuestionTableComp";
import { TableViewColumnCustom, CustomTable, useTableData } from "../tables/useCustomTable";
import { FormsQuestionService, UIService } from "../utils";
import { capitalize } from "@shopify/polaris/utilities/capitalize";

async function PromiseAllKeysAwaited<T extends Record<string, Promise<any>>>(proms: T): Promise<{ [K in keyof T]: Awaited<T[K]> }> {
  const entries = [...Object.entries(proms)].map(async ([k, v]) => ({ [k]: await v }));
  return (await Promise.all(entries)).reduce((n, e) => Object.assign(n, e), {} as any);
}


export function PageSystemLedger() {
  const { get, injector } = useAngular();
  const ui = get(UIService);
  const data = get(DataService);

  const tableData = useTableData(() => [
    { key: "Name", title: "Name", calculate: e => e.Name },
    { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
  ], [], async () => {

    const query = new DataQueryGraph("CentralHoldingLine", "", "web_admin");

    function payoutBalanceWhereLine() {
      return ({
        VoidSince: null,
        IS_TESTING: false,
        OR: [
          { invoiceLine: { paidOn: { not: null }, } },
          { paymentLine: { PaymentStatus: { in: ["Cleared", "Approved"] }, } },
          // { paymentLine: null, invoiceLine: null, }
        ]
      }) satisfies PrismaExtra.Prisma.TransactionWhereInput;
    }

    const proms = ({
      // BranchLedger: query.addPromise(data.proxy.branchLedger.aggregate({
      //   _sum: { Amount: true },
      //   where: {
      //     line: payoutBalanceWhereLine(),
      //   }
      // })),
      BranchLedger2: (async () => {
        // async clientBranchBalance(AND: any[]) {
        const { branches, salesTax } = await data.server.queryBranchBalance({ AND: [] });
        // branches.forEach(([branchID, balance]) => this.balanceLookup.map.set(branchID, balance));
        // const balances = [...state.customs?.balanceLookup.map.values() ?? []];
        const positiveBalance = branches.filter(([branchID, balance]) => balance > 0).reduce((n, [branchID, balance]) => n + balance, 0);
        const negativeBalance = branches.filter(([branchID, balance]) => balance < 0).reduce((n, [branchID, balance]) => n + balance, 0);
        return { positiveBalance, negativeBalance };
      })(),
      OwnerLedger: query.addPromise(data.proxy.ownerLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      DivisionLedger: query.addPromise(data.proxy.divisionLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      CentralLedger: query.addPromise(data.proxy.centralLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        },
      })),
      SalesTax: query.addPromise(data.proxy.salesTaxLedger.aggregate({
        _sum: { Amount: true },
        where: {
          line: payoutBalanceWhereLine(),
        }
      })),
      // Before february first, the payment fee was added on top of the amount the 
      // customer paid so it never entered the ledger and was handled by the customer.
      // After february first, the payment fee is deducted from the payout to branch and owner.
      // CustomerPaymentFee: query.addPromise(data.proxy.paymentLine.aggregate({
      //   _sum: { PaymentFee: true },
      //   where: {
      //     line: {
      //       ...payoutBalanceWhereLine(),
      //       customerLedgerLine: {},
      //       Date: { gte: "2024-02-01" },
      //     },
      //     PaymentStatus: { in: ["Cleared", "Approved"] },
      //   }
      // })),
      /** The owner and branch payment fee are set based on the transactions they apply to */
      OwnerPaymentFee: query.addPromise(data.proxy.invoiceLine.aggregate({
        _sum: { OwnerPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: {
            VoidSince: null,
            IS_TESTING: false,
          }
        }
      })),
      /** The owner and branch payment fee are set based on the transactions they apply to */
      BranchPaymentFee: query.addPromise(data.proxy.invoiceLine.aggregate({
        _sum: { BranchPaymentFee: true },
        where: {
          paidOn: { not: null },
          line: {
            VoidSince: null,
            IS_TESTING: false,
          }
        }
      })),
    });

    await data.dataGraphQuery(query, "requests");

    const {
      BranchPaymentFee,
      SalesTax,
      BranchLedger2: { negativeBalance, positiveBalance },
      OwnerPaymentFee,
      OwnerLedger,
      DivisionLedger,
      CentralLedger,
    } = await PromiseAllKeysAwaited(proms);


    return [
      { Name: "Central Ledger", Amount: CentralLedger._sum.Amount },
      { Name: "Division Ledger", Amount: DivisionLedger._sum.Amount },
      { Name: "Sales Tax", Amount: SalesTax._sum.Amount },
      // { Name: "Branch Balance", Amount: (BranchLedger._sum.Amount ?? 0) - (BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
      { Name: "Branch Balance Holding", Amount: (positiveBalance ?? 0) },
      { Name: "Branch Balance Owed by Branches", Amount: (negativeBalance ?? 0) },
      // { Name: "Branch Payment Fee (not subtracted)", Amount: (BranchPaymentFee._sum.BranchPaymentFee ?? 0) },
      { Name: "Owner Balance", Amount: (OwnerLedger._sum.Amount ?? 0) - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
      { Name: "Owner Payment Fee (subtracted)", Amount: (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0) },
      {
        Name: "System Holding Balance",
        Amount: 0
          + positiveBalance
          + (SalesTax._sum.Amount ?? 0)
          + (OwnerLedger._sum.Amount ?? 0)
          + (DivisionLedger._sum.Amount ?? 0)
          + (CentralLedger._sum.Amount ?? 0)
          // the payment fee is deducted by the processor so it does not exist in the holding balance
          - (BranchPaymentFee._sum.BranchPaymentFee ?? 0)
          - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0)
      },
      {
        Name: "Payout Holding Balance",
        Amount: 0
          + positiveBalance
          + (SalesTax._sum.Amount ?? 0)
          + (OwnerLedger._sum.Amount ?? 0)
          + (DivisionLedger._sum.Amount ?? 0)
          // + (CentralLedger._sum.Amount ?? 0)
          // the payment fee is deducted by the processor so it does not exist in the holding balance
          - (BranchPaymentFee._sum.BranchPaymentFee ?? 0)
          - (OwnerPaymentFee._sum.OwnerPaymentFee ?? 0)
      }
    ]
  }, [], "Name");


  // {
  //   idKey: "Name",
  //   curTab: 0,
  //   setTab: undefined,
  //   showFilters: false,
  //   onSelectRow: async () => { },
  //   tabLabels: [],
  // }

  return (
    <Page fullWidth>
      <BlockStack gap="300">
        <CustomTable
          tableData={tableData}
          curTab={0}
          setTab={undefined}
          showFilters={false}
          onSelectRow={async () => { }}
          tabLabels={[]}
        />
      </BlockStack>
    </Page>
  );

}
