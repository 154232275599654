import { useMemo } from "react";
import { getTableViews } from "../tables/table-views";
import { useTableListInner } from "../tables/TableListInner";
import { ColumnBase, KeyColumnBase } from "../utils";
import { Page } from "@shopify/polaris/index";


export function PageCentralReport() {
  const table = "CentralLedger";
  const views = useMemo(() => getTableViews(table) ?? [], [table]);
  return <Page fullWidth>{useTableListInner({
    table,
    views,
    pagination: true,
    rowsMapper: useGroupRows(),
    onSelectRow: () => { },
  }).useMarkup()}</Page>;

}


function useGroupRows() {
  return (notHiddenRows: any[], notHiddenCols: readonly ColumnBase[], idcol: ColumnBase) => {
    const groups = new Map<string, any[]>();
    console.log(notHiddenCols, notHiddenRows);
    notHiddenRows.forEach(row => {
      if (row.line.invoiceLine && !row.line.invoiceLine?.paidOn) return;
      const date = notHiddenCols[0].get(row);
      const key = date.slice(0, 7);
      if (!groups.has(key)) groups.set(key, []);
      groups.get(key)!.push(row);
    });
    const rows = [...groups.keys()].map(key => {
      const row = {};

      notHiddenCols.forEach(col => {
        if (!(col instanceof KeyColumnBase)) 
          throw new Error("Expected KeyColumnBase");
        if (col.key === "line/Date") 
          col.set(row, key + "-01");
        if (col.filterType === "currency")
          col.set(row, groups.get(key)!.map(e => col.agg(e)).reduce((n, e) => n + e, 0));
      });

      return row;
    });
    console.log(rows);
    return rows;
  };
}
