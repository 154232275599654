import { Page } from "@shopify/polaris";
import { } from "common";
import { DataService } from "data-service";
import { useState } from "react";
import { useAngular } from "react-utils";
import { AutopayTable } from "./useAutopayTable";


export function AutopayArlenPage() {
  const { get } = useAngular();
  const data = get(DataService);
  const [loadingAttemptAutopay, setLoadingAttemptAutopay] = useState(false);


  const [curTab, setTab] = useState(0);

  // const tableMarkup = useAutopayTable(undefined, curTab, setTab, true);

  return <Page title="Autopay Info" secondaryActions={[
    /* {
      content: "Attempt Autopay",
      loading: loadingAttemptAutopay,
      onAction: async () => {
        if (!data.status.isArlen) return alert("Not Arlen");
        setLoadingAttemptAutopay(true);
        try { await data.server.serverAttemptCustomerAutopay({}) } catch (e) { console.error(e); }
        setLoadingAttemptAutopay(false);
      }
    } */
  ]} fullWidth>
    <AutopayTable
      key={curTab}
      curBranch={undefined}
      curTab={curTab}
      setTab={setTab}
      showFilters={true}
    />
  </Page>;

}

