import { TextField } from "@shopify/polaris";
import { ConfirmType, MemberKeys } from "common";
import { QuestionInputNumber } from '../utils';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import Dinero from "dinero.js";
import { grabTextFieldInputRef, setInputField } from "./TextFieldHelpers";
import { useDispatch } from "react-utils";

function stringCount(str: string, char: string) {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === char) count++;
  }
  return count;
}

function useOkUnique(a: any) {
  const ref = useRef(a);
  if (ref.current !== a) throw new Error("useUnique");
}

export function QuestionInputNumberComp({ inputMode, value, onChange, mode, prefix, suffix, setErrors, ...commonProps }: {
  inputMode: "currency" | "decimal" | "integer";
  prefix?: string;
  suffix?: string;
  value: any;
  mode: MemberKeys<ConfirmType>;
  onChange: (v: any) => void;
  setErrors: (errors: any, opts?: any) => void;
  id: string;
  label: string;
  required: boolean;
  disabled: boolean;
  readOnly: boolean;
  autoComplete: string;
  helpText: string | undefined;
}) {
  // const inputRef = useRef<HTMLInputElement | null>(null);

  // const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const [raw, setRaw] = React.useState<string>("");
  // const [periodWarning, setPeriodWarning] = React.useState(false);
  // const [decimalWarning, setDecimalWarning] = React.useState(false);
  const [curErrorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const placeholder =
    (inputMode === "currency") ? "0.00" :
      inputMode === "decimal" ? "0.00" :
        inputMode === "integer" ? "0" :
          "";

  const maxdp = (inputMode === "currency") ? 2 :
    inputMode === "integer" ? 0 :
      20;

  const mindp = (inputMode === "currency") ? 0 : 0;

  // const onBlur2 = useCallback(() => {
  //   setRaw(raw => (!raw || raw.match(/^[$-0.]*$/)) ? "" : formatRawDecimal(raw.replace(/[^\d.-]/g, "").replace(/^0*/, ""), mindp, maxdp));
  // }, [mindp, maxdp]);

  const [prefix2, suffix2] = (inputMode === "currency") ? ["$", undefined] : [prefix, suffix];

  // useLayoutEffect(() => {
  //   if (typeof value === "number") {
  //     setRaw(raw => {
  //       const valstr = inputMode === "currency" ? toDollars(value).toString() : value.toString();
  //       const newval = checkDecimalValue(valstr, mindp, maxdp, 0).value;
  //       if (+raw !== +newval) return newval;
  //       return raw;
  //     });
  //   } else {
  //     setRaw("");
  //   }
  // }, [value, inputMode, mindp, maxdp]);

  const errors = {
    period: "A number can only have one period",
    required: "This field is required",
    isNaN: "This field contains characters that aren't a number",
    tooLong: "This number has too many decimal places",
    tooShort: "This number needs more decimal places",
  };

  useOkUnique(setErrors);

  const setErrorState = useCallback((error: (keyof typeof errors) | undefined) => {
    setErrors(error && { [error]: true });
    setErrorMessage(error && errors[error]);
  }, [setErrors]);

  // useLayoutEffect(() => {
  //   if (stringCount(raw, ".") > 1) { return setErrorState("period"); }
  //   if (raw === "" && commonProps.required) { return setErrorState("required"); }
  //   const value = raw === "" ? null : (inputMode === "currency" ? toCents(+raw) : +raw);
  //   if (Number.isNaN(value)) { return setErrorState("isNaN"); }
  //   setErrors(undefined);
  //   onChange(value);
  // }, [raw]);

  const onChange2 = useCallback((raw: string) => {
    setRaw(raw);
    console.log(raw, mindp, maxdp)
    if (stringCount(raw, ".") > 1) { return setErrorState("period"); }
    if (raw === "") {
      if (commonProps.required)
        return setErrorState("required");
      setErrors(undefined);
      setErrorMessage(undefined);
      onChange(null);
      return;
    }

    const [hi, lo] = raw.toString().split(".");
    if (lo && lo.length > maxdp) { return setErrorState("tooLong"); }
    if (mindp && lo && lo.length < mindp) { return setErrorState("tooShort"); }
    const value = (inputMode === "currency" ? toCents(+raw) : +raw);
    if (Number.isNaN(value)) { return setErrorState("isNaN"); }
    setErrors(undefined);
    setErrorMessage(undefined);
    onChange(value);
  }, [inputMode, mindp, maxdp, onChange]);

  useLayoutEffect(() => {
    console.log("setRaw", { value, inputMode });
    if (typeof value === "number") {
      setRaw(oldRaw => {
        const newRaw = (inputMode === "currency" ? toDollars(value) : value).toString();
        if (oldRaw !== newRaw) console.log("setRaw", { value, oldRaw, newRaw });
        return newRaw;
      });
    } else {
      setRaw("");
    }
  }, [value, inputMode, mindp, maxdp]);

  // useEffect(() => {
  //   if (!inputRef) return;
  //   const input = inputRef;
  //   const changeHandler = (event: InputEvent) => {
  //     // this just stops anything else from being entered in the field
  //     event.preventDefault();
  //   };
  //   const handler = (event: KeyboardEvent) => textFieldHandler({
  //     event, inputMode, mindp, maxdp,
  //     onChange, setRaw, setPeriodWarning, setDecimalWarning
  //   });

  //   input.addEventListener("beforeinput", changeHandler);
  //   input.addEventListener("keydown", handler);
  //   return () => {
  //     input.removeEventListener("keydown", handler);
  //     input.removeEventListener("beforeinput", changeHandler);
  //   };
  // }, [inputRef, inputMode, maxdp, mindp, onChange]);

  console.log("QuestionInputNumberComp", { raw, value, curErrorMessage });

  return (
    <TextField
      prefix={prefix2}
      suffix={suffix2}
      type="number"
      inputMode="decimal"
      value={raw}
      onChange={onChange2}
      placeholder={placeholder}
      {...commonProps}
      error={curErrorMessage}
    // helpText={undefined}
    />
  );


  //   <p-inputNumber
  //     *ngIf="q.inputMode === 'currency'"
  //     [formControl]="q.forminner"
  //     [inputId]="q.key"
  //     [autocomplete]="q.allowAutocomplete ? '' : 'off'"
  //     mode="currency"
  //     [currency]="$any(q.currency)"
  //     [useGrouping]="true"
  //     [allowEmpty]="true"
  //     [readonly]="isReadonly || false"
  //     [min]="$any(q.min)"
  //     [max]="$any(q.max)"
  //     class="flex"
  //   ></p-inputNumber>
}


const parsenumber = (raw: string, maxdp: number) => raw.replace(/[^\d-.]/g, "").split(".").lift(([h, l]) => `${h || "0"}${l ? `.${l.slice(0, maxdp)}` : ""}`);
const toCents = (dollars: number) => +`${dollars}e+2`;
const toDollars = (cents: number) => +`${cents}e-2`;

const formatRawDecimal = (raw: string, mindp: number, maxdp: number) => {
  const neg = raw.replace(/[^\d-.]/g, "")[0] === "-";
  const num = raw.replace(/[^\d.]/g, "");
  let [hi, lo] = num.split(".");
  hi = hi.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (lo === undefined && mindp) lo = "";
  const l2 = (lo === undefined) ? "" : (maxdp > 0 ? "." + lo.slice(0, maxdp).padEnd(mindp, "0") : "");
  return (neg ? "-" : "") + (hi || "0") + l2;
};


function cleanString(str: string, decimal: boolean, signed: boolean) {
  const old = str;
  // remove non-number characters
  str = str.replace(/[^\d-.]/g, "");
  // check for negative
  const negative = str.startsWith("-");
  // remove signs from the number
  str = str.replace(/-+/g, "");
  // remove leading and trailing zeros
  while (str.startsWith("0")) { str = str.slice(1); }
  while (str.includes(".") && str.endsWith("0")) { str = str.slice(0, -1); }
  // remove double dots
  while (str.includes("..")) str = str.replace("..", ".");
  // remove ending dot
  if (str.endsWith(".")) str = str.slice(0, -1);
  // if we've ended with an empty string, make it zero
  if (str === "") str = "0";
  // return the number (adding the minus back if needed)
  const res = (signed && negative ? "-" : "") + str;

  let i = 0, pos = 0;
  const posArray = [];
  for (; i < res.length && pos < old.length; (i++, pos++)) {
    while (old[pos] !== res[i] && pos < old.length) pos++;
    if (pos < old.length) posArray.push(pos);
  }
  // if (i === res.length) posArray.push()
  // posArray.push(posArray[posArray.length - 1] + 1);
  console.log("cleanString", old, res, posArray.map(i => res[i]));
  return { str: res, old, posArray };
}
function textFieldHandler({ event, inputMode, mindp, maxdp, setRaw, onChange, setPeriodWarning, setDecimalWarning }: {
  event: KeyboardEvent,
  inputMode: "currency" | "decimal" | "integer",
  mindp: number,
  maxdp: number,
  onChange: (v: any) => void,
  setRaw: React.Dispatch<React.SetStateAction<string>>,
  setPeriodWarning: React.Dispatch<React.SetStateAction<boolean>>,
  setDecimalWarning: React.Dispatch<React.SetStateAction<boolean>>,
}) {

  const input = event.target as HTMLInputElement;
  const setValue = (v: string, pos: number) => {
    input.value = v;
    input.setSelectionRange(pos, pos);
    checkDecimal(input, mindp, maxdp);
    setRaw(input.value);
    if (input.value === "-" || input.value === "-0" || input.value === "0") {
      onChange(0);
    } else if (input.value === "" || Number.isNaN(+input.value)) {
      onChange(null);
    } else {
      onChange(inputMode === "currency" ? toCents(+input.value) : +input.value);
    }
    event.preventDefault();
  }
  const cursor = (pos: number) => { input.setSelectionRange(pos, pos); };
  const decimal = inputMode === "decimal" || inputMode === "currency";
  if (input.selectionStart === null || input.selectionEnd === null) {
    console.error("selection range is null", input);
  } else if (input.selectionStart === input.selectionEnd) {
    const start = input.selectionStart;
    const raw = input.value;
    const neg = raw[0] === "-";
    const onlyOneDot = decimal && raw.split(".").length <= 2;

    console.log(raw);
    if (event.key === "Backspace") {
      if (onlyOneDot && raw[start - 1] === ".") {
        // this is our decimal, so just hop over it
        cursor(start - 1);
        event.preventDefault();
      } else if (raw.slice(+neg, start + 1) === "0.") {
        // this is the zero before the decimal, so remove the minus sign, but stay after it
        setValue(raw.slice(+neg), start - +neg);
      } else {
        // remove whatever is here
        setValue(raw.slice(0, start - 1) + raw.slice(start), start - 1);
      }
    } else if (event.key === "Delete") {
      if (onlyOneDot && raw[start] === ".") {
        // this is our decimal, so just hop over it
        cursor(start + 1);
        event.preventDefault();
      } else if (start === +neg && raw.slice(+neg, start + 2) === "0.") {
        // this is the zero before the decimal, so remove the minus sign, but stay after it
        cursor(start + 1);
        event.preventDefault();
      } else if (onlyOneDot && raw.slice(+neg, start + 1) === "0.") {
        // this is our decimal, so just hop over it
        cursor(start + 1);
        event.preventDefault();
      } else {
        setValue(raw.slice(0, start) + raw.slice(start + 1), start);
      }
    } else if (event.key === ".") {
      if (!decimal) {
        event.preventDefault();
      } else if (raw === "") {
        input.value = "0.".padEnd(mindp, "0");
        cursor(2);
        setRaw(input.value);
        event.preventDefault();
      } else if (raw[start] === ".") {
        cursor(start + 1);
        event.preventDefault();
      } else if (raw.includes(".")) {
        setPeriodWarning(true);
        event.preventDefault();
      } else {
        setValue(raw.slice(0, start) + "." + raw.slice(start), start + 1);
      }
    } else if (event.key === "-") {
      if (start === 0) {
        setValue("-" + raw, 1);
      } else if (start === 1 && raw.slice(0, 2) === "0.") {
        setValue("-0." + raw.slice(2), 2);
      } else {
        event.preventDefault();
      }
    } else if (/^[\d]$/.test(event.key)) {
      setValue(raw.slice(0, start) + event.key + raw.slice(start), start + 1);
    }
  } else {
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const raw = input.value;
    if (event.key === "Backspace" || event.key === "Delete") {
      setValue(raw.slice(0, start) + raw.slice(end), start);
    } else if (/^[\d.-]$/.test(event.key)) {
      // we don't restrict the decimal point here because that could be confusing
      setValue(raw.slice(0, start) + event.key + raw.slice(end), start + 1);
    } else {
      event.preventDefault();
    }
  }
  setRaw(raw => {
    console.log("raw", raw, input.value, input.selectionStart);
    return raw;
  })

}


function regexIsNumber() { return /^[\d.-]$/; }
const checkDecimal = (input: HTMLInputElement, mindp: number, maxdp: number) => {
  if (input.selectionStart === null) return;
  if (input.selectionEnd === null) return;

  const { value, position } = checkDecimalValue(input.value, mindp, maxdp, input.selectionStart);

  input.value = value;
  input.setSelectionRange(position, position);
}

function checkDecimalValue(value: string, mindp: number, maxdp: number, position: number) {

  if (value === "-0") return { value: "-0", position: 2 };
  if (value === "0") return { value: "0", position: 1 };
  if (value.includes(".")) {
    const [hi, lo] = value.split(".");
    value = hi + "." + lo.slice(0, maxdp).padEnd(mindp, "0");
  }
  const neg = value[0] === "-";
  if (value.slice(+neg)[0] === ".") {
    value = (neg ? "-" : "") + "0" + value.slice(+neg);
    position++;
  }
  while (value.slice(+neg)[0] === "0" && value.slice(+neg)[1] !== ".") {
    value = (neg ? "-" : "") + value.slice(+neg + 1);
    if (position > +neg) position--;
  }
  return { value, position };
}