import { Message, MessageService, useAngular, useSubscribe } from 'react-utils';
import { useRef, memo, Suspense, useState } from 'react';
import { Card, Frame, IconableAction, ModalProps, Page, Toast, TopBar } from '@shopify/polaris';
import { DataService } from "data-service";
import cubesLogo from "../../../assets/web-cubes-logo-text.png";
import { useObservable } from "react-utils";
import { Link, Outlet, useRouteError, isRouteErrorResponse } from "react-router-dom";
import { LinkLikeComponentProps } from '@shopify/polaris/utilities/link';
import { useToggle } from '@shopify/polaris/utilities/use-toggle';
import AppLeftBar from './leftbar';
import { ModelHostCounter } from '../utils';
import { map, Subscription } from 'rxjs';
import { counter } from './modal';
import { AuthService } from '../utils/auth.service';

function useUserMenu() {

  const { get } = useAngular();
  const data = get(DataService);
  const auth = get(AuthService);

  const { value: userMenuActive, toggle: toggleUserMenuActive } = useToggle(false);

  const delegatedUser = !!localStorage.getItem("cubes-creds-override");

  const userMenuActions = [
    {
      items: [
        {
          content: delegatedUser ? "Return to User" : "Logout",
          onAction: () => {
            if (delegatedUser) {
              localStorage.removeItem("cubes-creds-override");
              window.location.reload();
            } else {
              auth.logout();
            }
          }
        }
      ] satisfies IconableAction[],
    },
  ];
  const initial = data.status?.isAdmin ? "SA" : (data.status?.branchType ?? " ")[0];

  return (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={data.status?.email}
      detail={data.status?.branchName}
      initials={initial}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );
}


export function AppLayoutFrame() {

  const { get } = useAngular();
  const data = get(DataService);
  const auth = get(AuthService);


  const skipToContentRef = useRef<HTMLAnchorElement>(null);

  const skipToContentTarget = <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />;

  const ready = useObservable(data.ready, false);

  const error = useRouteError();
  if (error) console.log(error);

  const {
    value: mobileNavigation,
    toggle: toggleMobileNavigation,
    setTrue: openMobileNavigation,
    setFalse: closeMobileNavigation,
  } = useToggle(false);




  return <Frame
    logo={{
      width: 124,
      topBarSource: cubesLogo,
      contextualSaveBarSource: cubesLogo,
      url: '/Branch/dashboard',
      accessibilityLabel: 'Cubes Storage',
    }}
    topBar={<TopBar
      showNavigationToggle
      onNavigationToggle={toggleMobileNavigation}
      userMenu={useUserMenu()}
    />}
    navigation={<AppLeftBar onDismiss={closeMobileNavigation} />}
    showMobileNavigation={mobileNavigation}
    onNavigationDismiss={toggleMobileNavigation}
    skipToContentTarget={skipToContentRef}
  >
    <Suspense fallback={null}>
      {skipToContentTarget}
      {ready && !error && <Outlet />}
      {!!error && <ErrorBoundary />}
      <MessageHost />
    </Suspense>
  </Frame >
}
interface Message2 extends Message {
  [ModelHostCounter]: number;
  subs: Subscription;
}
function MessageHost() {
  const { get } = useAngular();
  const ms = get(MessageService);
  const [messageArr, setMessageArr] = useState<Message2[]>([]);
  useSubscribe(() => ms.messageObserver.pipe(map((e) => {
    const messages: Message[] = Array.isArray(e) ? e : [e];
    messages.forEach((message: any) => {
      message[ModelHostCounter] = counter.current++;
      message.subs = new Subscription();
    });
    return messages as Message2[];
  })).subscribe(async (messages) => {
    setMessageArr(current => [...current, ...messages]);
    messages.forEach(message => {
      // alert(message.summary + ": " + message.detail);
      // console.log(message);
      message.subs.add(() => {
        setTimeout(() => {
          setMessageArr(current => current.filter(d => d !== message));
        }, 500);
      });
    })
  }), [ms.messageObserver]);
  return <>{messageArr.map((message, i) => <ToastComp key={message[ModelHostCounter]} message={message} />)}</>
}
function ToastComp({ message }: { message: Message2; }) {
  return <Toast
    content={message.summary ?? ""}
    duration={5000}
    onDismiss={() => message.subs.unsubscribe()}
  />;
}
function NotFound() {
  return <Page title="Not Found" />
}

export function ErrorBoundary() {
  const error = useRouteError();

  return <Page title="Error" >
    <Card>
      {isRouteErrorResponse(error) ? (
        <div>
          <h1>Oops!</h1>
          <h2>{error.status}</h2>
          <p>{error.statusText}</p>
          {error.data?.message && <p>{error.data.message}</p>}
        </div>
      ) : (
        <div>
          <code>{(error as any).stack}</code>
        </div>
      )}
    </Card>
  </Page>
}
export function AppLinks({ url, ...rest }: LinkLikeComponentProps) {
  // console.log(url, rest);
  return <Link to={url} {...rest} />;
}

