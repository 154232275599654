import { recursiveindent, SCALAR_NAMES } from "./cubes-utils";
import {
  IsArray, RecordType, EnumType, RecordMember, Anything
} from "./graphql-declarator";

import { root } from "./cubes-index";
import { ScalarJSON } from "./cubes-schema";
// dirs.build();
// import "./printCubes-attributes";

const j = JSON.stringify;

export function printCubes_fulltypes() {
  const dirs = root;
  const mapMember = (v: Anything) => ([k, v2]: [string, RecordMember<any>]) => {
    let isArray = 0;
    while (v2 instanceof IsArray) { v2 = v2.__wrap; isArray++; }
    const gen = Reflect.getMetadata("StringGeneric", v.constructor.prototype, k)
      || (v2 instanceof ScalarJSON ? `PrismaJson.AllTypes[${j(v2.type)}]` : "");
    const type = v2.__is("enum") ? `EnumKeys<${v2.__name}>` : v2.__name;
    // const req = !isArray && v2.__required ? "!" : "?";
    return `${k}?: ${type}${gen ? `<${gen}>` : ''}${'[]'.repeat(isArray)}`;
  }
  const scals = Object.entries(dirs.scals).map(([k, v]) => {
    let type = "string", typedef = "string";
    switch (v.__name as SCALAR_NAMES) {
      case "Boolean": type = typedef = "boolean"; break;
      case "CubesDinero":
      case "Float":
      case "Int": type = typedef = "number"; break;
      case "ScalarJSON": type = "any"; typedef = "never"; break;
    }

    return `type ${v.__name}<T extends ${type} = ${typedef}> = T;`;
  }).join("\n");
  const enums = Object.values(dirs.enums).map((v: EnumType) => {
    return recursiveindent([
      `enum ${v.__name} {`, [
        ...v.__listMembers().map(([k, v]) => {
          return `${k} = ${j(k)},`;
        }),
      ], `}`
    ]);
  }).join("\n");
  const types = Object.values(dirs.types).map((v: RecordType) => {
    const gen = (v.constructor as any).G;
    const types = gen?.split(",").map((e: string) => e.trim().split("extends")[0].trim()).join(", ");
    return recursiveindent([
      `interface ${v.__name}${gen ? `<${gen}>` : ''} {`, [
        `__type?: ${j(v.__type)},`,
        ...v.__listMembers().map(mapMember(v)),
      ], `}`
    ]);
  }).join("\n");
  // const directives = Object.entries(dirs.directives).map(([k, v]) => {
  //   const gen = (v.constructor as any).G;
  //   const types = gen?.split(",").map((e: string) => e.trim().split("extends")[0].trim()).join(", ");
  //   const opts = Object.keys(v.__host).filter(e => v.__host[e]).map(e => j(e)).join(" | ");
  //   return recursiveindent([
  //     // `export class ${v.__name}${gen ? `<${gen}>` : ''} { __(attr: I${v.__name}${types ? `<${types}>` : ''}) { return {[${j(v.__name)}]: [attr] }; } }`,
  //     `export class ${v.__name}${gen ? `<${gen}>` : ''} extends DirectiveSimple${opts ? `<${opts}>` : ''} {`, [
  //       `constructor(public attr: {`,
  //       v.__listMembers().map(mapMember(v)),
  //       `}) { `,
  //       [
  //         `super();`,
  //       ],
  //       `}`
  //     ],
  //     `}`
  //   ]);
  // }).join("\n");



  function fixAttrs(v: any) {
    const attrs = JSON.parse(JSON.stringify(v));
    Object.keys(attrs).forEach((k) => { if (k !== "index") attrs[k] = attrs[k] && attrs[k].first(); });
    return attrs;
  }

  return [
    `/* THIS FILE IS AUTO-GENERATED BY npm run build */`,
    `// import { parentFilterArg } from "./cubes-schema-from-prisma";`,
    `// import { TableType } from "./cubes-schema-helpers";`,
    `// import { RecordType, RecordMember } from "./graphql-declarator";`,
    "type EnumKeys<T> = T extends `${infer X}` ? X : never",
    "export type MemberKeys<T> = T extends `${infer X}` ? X :",
    "  { [K in string & keyof T]: K extends `$${string}` ? never : K extends `__${string}` ? never : K; }[string & keyof T];",
    "// export class DirectiveSimple<T extends DirectiveOptions> { __host?: Record<T, true>; }",
    scals,
    enums,
    types,
    // directives,

    // recursiveindent([
    //   `interface _Scals {`,
    //   Object.values(root.scals).map(e => `${e.__name}: ${e.__name}`),
    //   `}`
    // ]),
    // recursiveindent([
    //   `interface _Enums {`,
    //   Object.values(root.enums).map(e => `${e.__name}: ${e.__name}`),
    //   `}`
    // ]),
    // recursiveindent([
    //   `interface _Types {`,
    //   Object.values(root.types).map(e => `${e.__name}: ${e.__name}`),
    //   `}`
    // ]),
    // recursiveindent([
    //   `interface _Attributes {`,
    //   Object.values(dirs.directives).map(v => {
    //     const gen = (v.constructor as any).G;
    //     const types = gen?.split(",").map((e: string) => "any").join(", ");
    //     return `${v.__name}: ${v.__name}${types ? `<${types}>` : ''}`
    //   }),
    //   `}`
    // ]),
    //     `
    // type ForHost<T, K, O extends DirectiveOptions = any> = T extends { __host?: Record<O, true> } ? K : never;
    // // type t2 = ForHost<_Attributes["field"], "field", "FIELD_DEFINITION">;
    // export type Attributes<O extends DirectiveOptions = any> = {
    //   [K in keyof _Attributes as ForHost<_Attributes[K], K, O>]?: _Attributes[K]["attr"][]
    // }
    // export type Directives<O extends DirectiveOptions = any> = {
    //   [K in keyof _Attributes as ForHost<_Attributes[K], K, O>]?: _Attributes[K]
    // }
    // export type DirectiveInput<O extends DirectiveOptions = any> = Directives<O>[keyof Directives<O>];
    // export type DirectiveOptions =
    //   | "SCHEMA"
    //   | "SCALAR"
    //   | "OBJECT"
    //   | "FIELD_DEFINITION"
    //   | "ARGUMENT_DEFINITION"
    //   | "INTERFACE"
    //   | "UNION"
    //   | "ENUM"
    //   | "ENUM_VALUE"
    //   | "INPUT_OBJECT"
    //   | "INPUT_FIELD_DEFINITION"
    //   ;
    // `
  ].join("\n")

}