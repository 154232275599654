import { ToSelectObject, isEmptyObject } from ".";
import { SPPI, setValueByPath, TABLE_NAMES, SelectTypeTree, GenericPathProxy, resolveDots, root } from "./schema-builder/cubes-index";
import { TableType } from "./schema-builder/cubes-schema-helpers";
import { RecordType, RecordMember, Wrapping } from "./schema-builder/graphql-declarator";

// type t1 = ToSelectObject<readonly [SPPI<["AutoPay", "Test"]>]>;
// type t2 = { readonly AutoPay: { select: ToSelectObject<t3>; }; }
// type t22 = DataListResult<"Rental", [["currentLocation"]]>;
// type t3 = Filter<t41, "AutoPay">;
// type t41 = t4 extends { [ARRAY_PATH_SYMBOL]: infer U } ? U : never
// type t4 = SPPI<["AutoPay", "Test"]>;



export class PrismaQuery {

  static orderByPath(arraySort: SPPI) {
    const dir = arraySort.startsWith("+") ? "asc" : arraySort.startsWith("-") ? "desc" : "";
    if (dir) arraySort = arraySort.slice(1) as SPPI;
    return setValueByPath({}, arraySort.split("/"), dir || "asc");
  }

  private static PathProxy(path: string[] = []): any {

    return new Proxy<any>({}, {
      get(t: any, p: string, r) {
        if (p === "__") {
          return path.join("/") as any;
        }
        return PrismaQuery.PathProxy([...path, p]);
      }
    });
  }

  static selectPathsProxy<T extends TABLE_NAMES, R extends readonly (SPPI | { key: SPPI; })[]>(
    type: T, a: (e: SelectTypeTree<T>) => R
  ): number extends R["length"] ? "selector must return a const array" : ToSelectObject<R> {

    const list: (SPPI | { key: SPPI; })[] = GenericPathProxy()(a);
    if (!Array.isArray(list) || !list.length) return {} as never;
    return list.reduce((n, e) => PrismaQuery.walk(n, resolveDots("", typeof e === "string" ? e : e.key).split("/"), root.types[type], false), {} as any);

  }
  static getQueryTree(list: SPPI[], item: RecordType, forInclude: boolean, base: any = {}) {
    if (!list || !list.length) return {};
    return list.reduce((n, e) => PrismaQuery.walk(n, resolveDots("", e).split("/"), item, forInclude), base as Record<string, any>);
  }
  static walk(acc: Record<string, any>, path: string[], item: RecordMember<any>, forInclude: boolean): Record<string, any> {
    if (!path.length) return acc;

    path = path.slice();
    let first = item[path[0] as string as keyof typeof item] as RecordMember<any>;

    if (!first) {
      debugger;
      throw new Error(path[0] + " does not exist on " + item.__name);
    }
    if (first instanceof Wrapping) {
      first = first.__wrap;
    }
    if (first instanceof TableType) {
      const item = acc[path[0]] ?? { select: {} };
      this.walk(item.select, path.slice(1), first, false);
      if (!isEmptyObject(item.select)) acc[path[0]] = item;
    } else if (!forInclude) {
      acc[path[0]] = true;
    }
    return acc;
  }


}
