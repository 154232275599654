



import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useNavigate } from "react-router-dom";
import { NgContextProvider, ReactInjectorRouterHack, i18n, rootInjector } from "react-utils";
import { AppProvider } from "@shopify/polaris/index";
import { AppLayoutFrame, AppLinks, ErrorBoundary } from "./AppLayoutFrame";
import { ModalHost } from "./modal";
import { LoginHost } from "./login";
import { ProvideBranchSelector } from "../utils";
import { AppLayoutBody } from '../pages';




export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<ReactRootView />} errorElement={<ReactRootView />}  >
      <Route errorElement={<ErrorBoundary />} >
        <Route path=":table/:view/:id" element={<AppLayoutBody />} />
        <Route path=":table/:view" element={<AppLayoutBody />} />
        <Route path=":table" element={<AppLayoutBody />} />
        <Route path="" element={<AppLayoutBody />} />
      </Route>
    </Route>
  ));


export function ReactRootView() {

  ReactInjectorRouterHack.globalNavigate = useNavigate();

  return (
    <NgContextProvider injector={rootInjector}>
      <AppProvider linkComponent={AppLinks} features={{ polarisSummerEditions2023ShadowBevelOptOut: true }} i18n={i18n}>
        <ProvideBranchSelector>
          <AppLayoutFrame />
          <ModalHost />
          <LoginHost />
        </ProvideBranchSelector>
      </AppProvider>
    </NgContextProvider>
  );
}


export default function App() {
  return <RouterProvider router={router} />
}